import React from 'react'
import {pie, arc} from 'd3'

const size = 250

function CircleBar({
  className,
  style,
  color,
  value,
  animated = false,
}) {
  const currentScore = value
  // const [currentScore, setScore] = useState(0)
  // const [timestamp, setTimestamp] = useState(performance.now())
  // useEffect(() => {
  //   if(currentScore === value) return setScore(value)
  //   if(!animated || currentScore > value) return setScore(value)
  //   let now = performance.now()
  //   let delta = (now - timestamp) / 1000
  //   setTimestamp(now)
  //   setScore(currentScore + delta * 0.5)
  // }, [currentScore, animated, value, timestamp])

  const pieData = pie().sort(null).value(d => d.value)([
    {value: currentScore, shouldShow: true},
    {value: 1 - currentScore, shouldShow: false},
  ])[0]


  const pathArc = arc().outerRadius(size / 2).innerRadius((size / 2) - 30)(pieData)
  return (
    <div style={style} className={className}>
      <svg height={size + 10} width={size + 10}>
        <g transform={`translate(${(size + 10)/2},${(size + 10)/2})`}>
          <text
            fill={color}
            strokeColor={color}
            textAnchor='middle'
            dominantBaseline='middle'
            x={0}
            y={0}
            style={{font: 'bold 36px "Poppins", sans-serif'}}
            >{Math.round(currentScore * 100)}%</text>
          <g>
            <path
              d={pathArc}
              fill={color}
              />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default React.memo(CircleBar)
