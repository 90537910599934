import React, { useState } from 'react'

import axios from 'axios'
import { getJwt, getTestConference, deleteTestConference, apiBase } from '../../utilities/api.js'

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'

import {
  Redirect,
} from 'react-router-dom'

import Page from '../components/page.js'
import Question from '../components/test/question'
import TestQuestions from '../components/test/testQuestions'
import { CircularProgress } from '@material-ui/core';

const btnStyle = {
  margin: '20px',
  width: '75%',
  fontSize: 20
}


function Test({ conference }) {
  const [showResults, setShowResults] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [{ tips, results }, setTestData] = useState({ tips: new Set(), results: [] })
  const [scrollToQuestion, setScrollToQuestion] = useState(null)

  if (showResults) return <Redirect to={`/results/${showResults}`} />
  if (!getTestConference() || !getJwt()) return <Redirect to='/' />

  const testFilled = results.length === TestQuestions.length

  const handleTips = ({ value, selected, }) => {
    let tips_copy = new Set(tips)
    if (value) {
      if (tips_copy.has(value) && !selected) tips_copy.delete(value)
      else if (selected) tips_copy.add(value)
    }
    return tips_copy
  }

  const handleResults = ({ name, type, value, }) => {

    let results_copy = results.map(result => ({ ...result })) // Deep copy
    let index = results.map(el => { return el.name }).indexOf(name)
    if (index === -1) results_copy.push({ name, type, value })
    else results_copy[index] = { name, type, value }
    return results_copy
  }

  const handleQuestionUpdate = ({ tip, result, }) => {
    const tips = handleTips(tip)
    const results = handleResults(result)
    setTestData({ tips, results })
    setScrollToQuestion(null)
  }

  const handleGoToUnansweredQuestion = () => {
    const ids = new Set(results.map(a => a.name))
    let id
    for(id = 0; id < TestQuestions.length && ids.has(id); id++) {}
    console.log('scrolling to id', id)
    setScrollToQuestion(id)
  }

  const handleSubmit = () => {
    if (submitting) return
    setSubmitting(() => true)
    const scores = results.reduce((sum, next) => ({
      ...sum,
      [next.type.toLowerCase()]: sum[next.type.toLowerCase()] ? sum[next.type.toLowerCase()] + next.value : next.value,
    }))
    const testData = {
      tips: [...tips],
      connect: scores.connector,
      convey: scores.conveyor,
      convince: scores.convincer,
    }
    console.log('RESULT DATA GOING UP:', testData)
    axios
      .post(`${apiBase}/user/test/for_conference/${getTestConference()}`, testData, {
        headers: {
          'Authorization': getJwt(),
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        deleteTestConference()
        setShowResults(() => data.testId)
      })
      .catch(({ response }) => {
        console.error(response)
        alert('Your network appears to be slow. Please try again in a minute. If you see this message again, please try again on a different computer.')
        setSubmitting(() => false)
      })
  }

  return (
    <Page title={'How influential are you?'}>
      <Typography align='left' variant='body1' color='textSecondary'>
        Are you ready to find out how deep your influence goes? The Influence Assessment takes less than 10 minutes, so be sure to consider your answers carefully and answer honestly, even if your answer makes you uncomfortable. Try not to answer with “middle of the road” or neutral answers unless they truly reflect you or how you feel.
      </Typography>
      <form>
        {TestQuestions.map((question, index) => {
          question.name = index
          return (
            <Question
              question={question}
              handle={handleQuestionUpdate}
              key={question.name}
              scrollIntoView={scrollToQuestion === index}
              />
          )
        })}
        {submitting ?
          <CircularProgress />
          :
          (
            testFilled ?
              <Button
                onClick={handleSubmit}
                color='secondary'
                variant='contained'
                size='large'
                style={btnStyle}
              >
                Submit
        </Button>
              :
              <Button
                onClick={handleGoToUnansweredQuestion}
                color='primary'
                variant='outlined'
                size='large'
                style={btnStyle}
              >
                Go To Unanswered Question
        </Button>
          )

        }

      </form>
    </Page>
  )
}

export default React.memo(Test)
