import React from 'react'

class SVGRef extends React.Component {
  shouldComponentUpdate(nextProps) {
    // return false
    return this.props.height !== nextProps.height || this.props.width !== nextProps.width
  }

  render() {
    const { children, height, width, setRef, style = {}, shouldTransform=false } = this.props
    return (
      <svg
        style={{ ...style }}
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        height={height}
        width={width}
        ref={node => node ? setRef(node) : null}
        fill='none'
      >

        <rect width="100%" height="100%" fill="none" />
        <g transform={shouldTransform ? `translate(${width / 2}, ${height / 2})` : undefined}>
          {children}
        </g>
      </svg>
    )
  }
}

export default SVGRef
