import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {apiBase} from '../../../utilities/api.js'

import {
  CircularProgress,
} from '@material-ui/core'

import {
  makeStyles,
} from '@material-ui/core/styles'

import Page from '../../components/page.js'
import ConferenceTable from './conferenceTable.js'

const useStyles = makeStyles(theme => ({
  spacer: {
    marginTop: theme.spacing(2)
  }
}))

const apiURL = `${apiBase}/admin/conference`

const formatDateField = data => {
  let d = new Date(data)
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
}

function ManageConference({userData}) {
  const now = new Date()

  const [isLoading, setLoading] = useState(true)
  const [conferences, setConferences] = useState([])

  useEffect(() => {
    if(!isLoading) return
    axios.get(apiURL, {
      headers: {
        'Authorization': userData.jwt
      },
    })
      .then(({data}) => {
        setLoading(false)
        setConferences(data)
      })
      .catch((response) => console.error(response))
  }, [isLoading, userData.jwt])

  const [upcoming, open, closed] = conferences.map(conference => ({...conference, opens: formatDateField(conference.opens), closes: formatDateField(conference.closes)})).reduce((acc, conference) => {
    let openDate = new Date(conference.opens)
    let closeDate = new Date(conference.closes)
    if(openDate > now) {
      acc[0].push(conference)
    } else if(openDate < now && closeDate > now) {
      acc[1].push(conference)
    } else {
      acc[2].push(conference)
    }
    return acc
  },[[],[],[]])

  const classes = useStyles()
  const onAdd = newData => new Promise(resolve => {
    axios.post(apiURL, newData, {
      headers: {
        'Authorization': userData.jwt,
      }
    })
      .then(() => {
        resolve()
        const updatedConferences = conferences.slice()
        updatedConferences.push(newData)
        setConferences(updatedConferences)
      })
  })

  const onEdit = (newData, oldData) => new Promise(resolve => {
    axios.patch(`${apiURL}/${oldData.id}`, newData,{
      headers: {
        'Authorization': userData.jwt,
      },
    })
    .then(() => {
      resolve()
      const index = conferences.findIndex(d => d.passcode === oldData.passcode)
      const updatedConferences = conferences.slice()
      updatedConferences[index] = newData
      setConferences(updatedConferences)
    })
    .catch(() => {
      resolve()
      alert('Please ensure that the code is unique. If the code has not been used before, please contact Asher to investigate.')
    })
  })

  const onDelete = oldData => new Promise(resolve => {
    axios.delete(`${apiURL}/${oldData.id}`, {
      headers: {
        'Authorization': userData.jwt,
      },
    })
    .then(() => {
      resolve()
      const index = conferences.findIndex(d => d.passcode === oldData.passcode)
      const updatedConferences = conferences.slice()
      updatedConferences.splice(index,1)
      setConferences(updatedConferences)
    })
  })

  return (
    <Page title='Manage Conferences' loggedIn={userData.loggedIn}>
      {isLoading ?
        <CircularProgress variant='indeterminate' disableShrink />
        :
        (
          <div>
            <ConferenceTable title='Future Conferences' conferences={upcoming} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} />
            <ConferenceTable title='Active Conferences' className={classes.spacer} conferences={open} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} />
            <ConferenceTable title='Archived Conferences' className={classes.spacer} conferences={closed} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} />
          </div>
        )
      }
    </Page>
  )
}

export default ManageConference
