import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import ConferenceVisualization from './heatmap.js'

function Container({
  tests,
  className = '',
}) {
  const userIdCounts = {}
  const testBreakDown = tests.reduce((acc, test) => {
    const newAcc = acc.map(tests => tests.slice())
    const addToAcc = index => {
      if(newAcc.length === index) newAcc.push([])
      newAcc[index].push(test)
    }
    const getIndex = () => {
      const key = `user_${test.user_id}`
      let index = 0
      if(userIdCounts.hasOwnProperty(key)) {
        index = userIdCounts[key]++
      } else {
        userIdCounts[key] = 1
      }
      return index
    }
    addToAcc(getIndex())
    return newAcc
  }, [])
  const hasMultipleTests = testBreakDown.length > 1
  if(testBreakDown.length === 0) return <Typography variant={'body1'}>No Test Data</Typography>
  return (
    <Grid className={className} container spacing={2}>
      <Grid item xs={hasMultipleTests ? 6 : 12}>
        <ConferenceVisualization title={hasMultipleTests ? 'First Test' : 'Heatmap'} tests={testBreakDown[0]} size={hasMultipleTests ? 350 : 800} />
      </Grid>
      {hasMultipleTests &&
        <Grid item xs={6}>
          <ConferenceVisualization title='Latest Test' tests={testBreakDown[testBreakDown.length - 1]} size={350} />
        </Grid>
      }
    </Grid>
  )
}

export default Container
