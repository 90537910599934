import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'


class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      conference: ''
    }
  }
  render() {
    const {
      classes,
    } = this.props
    return (
      <div className={classes.root}>
        <Typography color='textSecondary' variant='body2'>© 2016-2018 Connect Convey Convince ™ Connie Dieken. All rights reserved.</Typography>
      </div>
    )
  }
}

export default withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.others.gray,
    display: 'flex',
    padding: theme.spacing(2),
  }
}))(Footer)
