import React from 'react'
import axios from 'axios'
import Datasets from 'datasets'
import Table from 'components/table'

const {
  generateLookup,
  generateSelect: makeSelect,
} = Datasets

const columns = [
  { title: 'Name', field: 'name' },
  { title: 'Email', field: 'email' },
  { title: 'Title', field: 'title', editComponent: makeSelect(Datasets.titles), lookup: generateLookup(Datasets.titles) },
  { title: 'Industry', field: 'industry', editComponent: makeSelect(Datasets.industries), lookup: generateLookup(Datasets.industries) },
  { title: 'Gender', field: 'gender', editComponent: makeSelect(Datasets.genders), lookup: generateLookup(Datasets.genders) },
  { title: 'Age', field: 'age', editComponent: makeSelect(Datasets.ages), lookup: generateLookup(Datasets.ages) },
  { title: 'Location', field: 'location', editComponent: makeSelect(Datasets.locations), lookup: generateLookup(Datasets.locations) },
]

const UserTable = ({
  data,
  getUpdateUserData = user => user,
  onUpdated = () => {},
  title = 'Users',
  jwt,
  includeConferences = false,
}) => {
  const cols = includeConferences ? [{
    title: 'Conference', field: 'conference',
  }].concat(columns) : columns
  return (
    <Table
      title={title}
      columns={cols}
      data={data}
      options={data.length > 1 ? {
        pageSizeOptionss: [5,10,20,data.length]
      } : {
        search: false,
        pageSize: 1,
        pageSizeOptions: [1],
        showFirstLastPageButtons: false,
        sorting: false,
        paging: false,
      }}
      editable={{
        onRowUpdate: (newData, oldData) => {
          const upload = getUpdateUserData(newData, oldData)
          return axios.patch(`/api/admin/user/${upload.id}`, upload, {
            headers: {
              Authorization: jwt,
            }
          })
            .then(() => onUpdated(upload))
        },
      }}
    />
  )
}

export default UserTable