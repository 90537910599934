import React, { useEffect, useState } from 'react'

import UserTable from '../components/userTable'
import axios from 'axios'
import { CircularProgress } from '@material-ui/core';

function Users({
  userData: {jwt},
}) {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  useEffect(() => {
    axios.get('/api/admin/user/missing', {
      headers: {
        Authorization: jwt,
      }
    })
      .then(({data}) => {
        console.log('DATA', data)
        setUsers(data)
        setLoading(false)
      })
      .catch(({response}) => console.log(response))
  }, [jwt])
  if(loading) return <CircularProgress />
  return (
    <UserTable
      includeConferences
      title="Complete These Users' Demographics"
      jwt={jwt}
      data={users}
      onUpdated={userData => {
        const newUsers = users.slice()
        const index = users.findIndex(u => u.id === userData.id)
        newUsers[index] = userData
        setUsers(newUsers)
      }}
    />
  )
}

export default Users
