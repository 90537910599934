import React from 'react'
import Axis from '../shared/axis.js'

function Axes({
  scales = {},
  margins = {},
  dimensions: {
    height, width,
  },
  convertToPercentages,
}) {
  const props = {}
  if(scales.x) {
    props.x = {
      orient: 'Bottom',
      scale: scales.x,
      transform: `translate(0, ${height - margins.bottom})`,
    }
    if(convertToPercentages) {
      props.x.tickFormat = d => d * 100
    }
  }
  if(scales.y) {
    props.y = {
      orient: 'Left',
      scale: scales.y,
      transform: `translate(${margins.left}, 0)`,
      ticks: 5,
    }
  }
  return (
    <g>
      {scales.x && <Axis {...props.x} />}
      {scales.y && <Axis {...props.y} />}
    </g>
  )
}

export default Axes
