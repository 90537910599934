import React, {useState} from 'react'
import axios from 'axios'
import {apiBase} from '../../utilities/api'

import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

import {Redirect} from 'react-router-dom'

import { withStyles, useTheme } from '@material-ui/core/styles'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import SpacerBar from '../../components/spacerBar'

function Login({
  classes,
  setJwt = () => {},
  loggedIn
}) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleChange = handler => e => handler(e.target.value)
  const theme = useTheme()
  const handleSubmit = () => {
    axios.post(`${apiBase}/admin/authenticate`, {
      username,
      password,
    })
      .then(({data}) => {
        if(data.success) {
          setJwt(data.token)
        } else {
          alert(data.error)
        }
      })
      .catch(({response}) => console.error(response))
  }
  if(loggedIn) return <Redirect to='/admin' />
  return (
    <Container>
      <Paper className={classes.paper}>
        <Typography variant='h3'>Admin Login</Typography>
        <SpacerBar className={classes.spacer} color={theme.palette.primary.main} />
        <form>
          <div>
            <TextField
              onChange={handleChange(setUsername)}
              value={username}
              variant='outlined'
              margin='normal'
              label='Username'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' style={{visibility: 'hidden'}}>
                    <IconButton>
                      <Visibility />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              />
          </div>
          <div>
            <TextField
              onChange={handleChange(setPassword)}
              value={password}
              variant='outlined'
              margin='normal'
              label='Password'
              type={passwordVisible ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setPasswordVisible(v => !v)}>
                      {passwordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              />
          </div>
          <Button
            color='primary'
            size='large'
            variant='contained'
            onClick={handleSubmit}
            >Login</Button>
        </form>
      </Paper>
    </Container>
  )
}

export default withStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  spacer: {
    marginTop: theme.spacing(1)
  }
}))(Login)
