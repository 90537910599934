import 'date-fns'
import React from 'react'
import { MTableToolbar } from 'material-table'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import {makeStyles} from '@material-ui/core/styles'


import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import Table from 'components/table.js'

const useStyles = makeStyles(theme => ({
  pipeBar: {
    color: theme.palette.influence.convey,
    fontWeight: 100,
  }
}))


const makeDateEdit = () => props => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      margin="normal"
      value={props.value}
      onChange={d => props.onChange(`${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`)}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      />
  </MuiPickersUtilsProvider>
)

function ConferenceTable({
  conferences,
  className = '',
  onGenerateReport,
}) {
  const classes = useStyles()
  const columns = [
    {title: 'Group', field: 'tag'},
    {title: 'Code', field: 'passcode'},
    {title: 'Opens', editComponent: makeDateEdit(), field: 'opens'},
    {title: 'Closes', editComponent: makeDateEdit(), field: 'closes'},
    {title: 'Tests', field: 'allowedAttempts'},
    {title: 'Users', render: d => d ? d.second && d.second > 0 ? <span>{d.first}<span className={classes.pipeBar}> | </span>{d.second}</span> : <span>{d.first}</span> : <span>0</span>}
  ]
  return (
    <div className={className}>
      <Table
        title = 'Conferences'
        columns = {columns}
        data = {conferences}
        options={{
          search: false,
          filtering: true,
          selection: true
        }}
        components={{
          Toolbar: props => {
            return (
              <Grid container>
                <Grid item>
                  <MTableToolbar {...props} />
                </Grid>
                {props.selectedRows.length > 0 &&
                  <Grid item>
                    <Button
                      color='secondary'
                      style={{height: '100%'}}
                      onClick={() => onGenerateReport(props.selectedRows)}
                      >Generate Conference Report</Button>
                  </Grid>
                }
              </Grid>
            )
          }
        }}
        />
    </div>
  )
}

export default ConferenceTable
