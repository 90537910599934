import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import {
  Redirect,
  withRouter,
} from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import Datasets from 'datasets'
import { getTestConference, setJwt, getJwt } from '../../utilities/api.js'


import Page from '../components/page.js'
import axios from 'axios';

const fields = [
  { label: 'First Name', key: 'fname' },
  { label: 'Last Name', key: 'lname' },
  { label: 'Email', key: 'email' },
  { label: 'Job Title', key: 'title', options: Datasets.titles },
  { label: 'Industry', key: 'industry', options: Datasets.industries },
  { label: 'Gender', key: 'gender', options: Datasets.genders },
  { label: 'Age Range', key: 'age', options: Datasets.ages },
  { label: 'Location', key: 'location', options: Datasets.locations },
]

const LetsMeet = ({
  classes,
  createUser,
}) => {
  const [state, setState] = useState({
    fname: '',
    lname: '',
    email: '',
    title: '',
    industry: '',
    gender: '',
    age: '',
    location: '',
    clicked: false,
  })

  const [clicked, setClicked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [creationError, setCreationError] = useState(false)
  useEffect(() => {
    if(loading || !clicked) return
    setLoading(true)
    axios
      .post(`/api/user/conference/${getTestConference()}`, {...state, name: `${state.fname} ${state.lname}`})
      .then(({data}) => {
        if(!data.success) {
          setLoading(false)
          setClicked(false)
          setCreationError(data.error)
        } else {
          setLoading(false)
          setJwt(data.token)
        }
      })
  }, [clicked, loading, state])

  if (getJwt()) return <Redirect to='/test' />
  const handleChange = key => e => setState({...state, [key]: e.target.value })
  const emailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(state.email)
  // const formReady = Object.keys(state)
  //   .reduce((isFilled, key) => isFilled && (key === 'clicked' || state[key]), emailValid)
  const formReady = emailValid

  return (
    <Page title={`Let's Meet`}>
      <Paper className={classes.container}>
        <Typography className={classes.info} variant='body1' color='textSecondary'>
          Please complete this brief form to receive your results.
          We will not share your personal information with other individuals or organizations.
          </Typography>
        <form className={classes.form}>
          <Grid container>
            {fields.map((field, index) => {
              const className = index % 2 === 0 ? classes.gridItemLeft : classes.gridItemRight
              const key = field.label + '_key'
              if (field.options) {
                return (
                  <Grid key={key} item xs={6} className={className}>
                    <FormControl variant='outlined' className={classes.formControl}>
                      <InputLabel>
                        {field.label}
                      </InputLabel>
                      <Select
                        value={state[field.key]}
                        onChange={handleChange(field.key)}
                        input={<OutlinedInput name={field.label} labelWidth={100} />}
                      >
                        {field.options.map(option => {
                          if (option.value) {
                            return (
                              <MenuItem key={option.value + option.label} value={option.value}>{option.label}</MenuItem>
                            )
                          }
                          return <MenuItem key={option} value={option}>{option}</MenuItem>
                        })}

                      </Select>
                    </FormControl>
                  </Grid>
                )
              }
              return (
                <Grid key={key} item xs={6} className={className}>
                  <TextField
                    error={field.key === 'email' && !emailValid && state.email}
                    helperText={field.key === 'email' && !emailValid && state.email ? 'Invalid Email' : null}
                    label={field.label}
                    value={state[field.key]}
                    onChange={handleChange(field.key)}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    className={classes.input}
                  />
                </Grid>
              )
            })}
          </Grid>
          {creationError ? <Typography className={classes.warningMessage} variant='body2'>{creationError}</Typography> : <div />}
          {loading ?
            <CircularProgress disableShrink='indeterminate' color='secondary' />
            :
            <Button
              onClick={() => setClicked(true)}
              color='secondary'
              variant='contained'
              disabled={!formReady && !clicked}
            >Submit</Button>
          }
        </form>
      </Paper>
    </Page>
  )
}

export default withRouter(withStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    textAlign: 'left',
    marginTop: theme.spacing(3),
  },
  form: {
    marginTop: theme.spacing(1),
  },
  gridItemRight: {
    paddingLeft: theme.spacing(3),
  },
  gridItemLeft: {
    paddingRight: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px',
  },
  warningMessage: {
    color: 'red',
  }
}))(LetsMeet))
