import React from 'react'
import {
  line,
  mean,
  curveBasis,
  scaleLinear,
} from 'd3'

/** 
 * Applies a kernel function to convert the data
 * into a cleaner, continuous looking function
*/
const kde = (kernel, thresholds, data) => thresholds
  .map(threshold => [threshold, mean(data, d => kernel(threshold - d))])

/**
 * Kernel with 100% accuracy in smoothing histograms
 * Note that bandwidth greatly affects this...
 */
const epanechnikov = bandwidth => x => {
  const nX = x / bandwidth
  if (Math.abs(nX) > 1) return 0
  return 0.75 * (1 - nX * nX) / bandwidth
}

const CDF = ({
  color = '#000',
  data,
  scales: {
    x,
    y,
  },
  bandwidth,
  transform = '',
}) => {
  const yScale = scaleLinear()
    .domain([y.domain()[0], y.domain()[1] / Math.sqrt(data.length)])
    .range(y.range())
  const density = kde(epanechnikov(bandwidth), x.ticks(), data) 
  const cdfPathGen = line()
    .curve(curveBasis)
    .x(d => x(d[0]))
    .y(d => yScale(d[1]))
  const cdfPath = cdfPathGen(density)
  console.log(density, yScale.domain())
  return (
    <path 
      fill='none'
      stroke={color}
      strokeWidth={1.5}
      strokeLinejoin='round'
      transform={transform}
      d={cdfPath}
    />
  )
}

export default CDF