import React from 'react'
import Typography from '@material-ui/core/Typography';

import SpacerBar from '../components/spacerBar.js'
import { withStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core';

class Page extends React.Component {
  render() {
    const { title, classes, children } = this.props
    return (
      <main>
        <Container>
          <Typography className={classes.title} variant="h2" color='textSecondary'>{title}</Typography>
          <SpacerBar className={classes.spacer} />
          {children}
        </Container>
      </main>
    )
  }
}


export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  spacer: {
    marginBottom: theme.spacing(3),
  }
}))(Page)
