import React, {useState} from 'react'

import axios from 'axios'
import {apiBase, setTestConference, getTestConference} from '../../utilities/api.js'

import Typography from '@material-ui/core/Typography';
import {
  Redirect,
  withRouter,
} from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import Page from '../components/page.js'

function Home ({
  classes
}) {
  const [conference, setConference] = useState('')
  const [validConference, setValidConference] = useState(false)

  const handleChange = handler => e => handler(e.target.value)
  const validateConference = () => axios.get(`${apiBase}/conference/${conference}`)
    .then(({data: {success}}) => {
      setTestConference(conference)
      setConference('')
      setValidConference(success)
    })
    .catch(err => console.error(err))

  if (validConference) return <Redirect to='/lets_meet' />
  let conferenceError = ''
  if (getTestConference()) conferenceError = 'Please make sure you entered your conference passcode correctly.'
  return (
    <Page title='Welcome!'>
      <Paper className={classes.container}>
        <div className={classes.inputGroup}>
          <TextField
            onChange={handleChange(setConference)}
            value={conference}
            margin='normal'
            label='Click here to enter your Conference Code'
            className={classes.input}
            />
          <div className={classes.buttonContainer}>
            <Button
              color='secondary'
              variant='contained'
              onClick={validateConference}>Submit</Button>
          </div>
        </div>
        {conferenceError ? <Typography className={classes.inputError} variant='body2'>{conferenceError}</Typography> : <div />}
      </Paper>
    </Page>
  )
}

export default withRouter(withStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  input: {
    width: '360px',
  },
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  inputGroup: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  buttonContainer: {
    marginLeft: theme.spacing(2),
  },
  inputError: {
    textAlign: 'center',
    color: theme.palette.status.error,
  }
}))(Home))
