import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {apiBase} from '../utilities/api'

import {
  Switch,
  Route,
  withRouter,
} from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'

import NavBar from './components/navbar'
import Home from './pages/home'
import ManageConference from './pages/manageConference'

import DataExploration from './pages/dataExploration'
import ManageUser from './pages/manageUser.js'
import Settings from './pages/settings.js'
import Login from './pages/login'

function AdminContainer(props) {
  const [isLoading, setLoading] = useState(true)

  const [jwt, setJwt] = useState(null)
  const [features, setFeatures] = useState(new Set())
  const [loggedIn, setLoggedIn] = useState(false)

  const userData = {
    features,
    jwt,
    loggedIn,
  }

  useEffect(() => {
    axios.get(`${apiBase}/admin/authenticate`, {
      headers: {
        'Authorization': jwt,
      },
    })
      .then(({data}) => {
        const userFeatures = new Set(data.features.map(feature => feature.feature))
        userFeatures.add('all')
        setFeatures(userFeatures)
        setLoading(false)
        setLoggedIn(true)
      })
      .catch(err => {
        setLoading(false)
        setLoggedIn(false)
      })
  }, [isLoading, jwt, loggedIn])
  return (
    <div>
      <header>
        <NavBar allowedFeatures={features} onLogout={() => setJwt(false)} />
      </header>
      <div style={{ marginTop: '30px', minHeight: '85vh' }}>
        {isLoading ?
          <CircularProgress />
          :
          <Switch>
            <Route path='/admin/login' render={(routeProps) => (<Login {...routeProps} setJwt={setJwt} loggedIn={loggedIn} />)} />
            <Route path='/admin/conferences' render={(routeProps) => (<ManageConference {...routeProps} userData={userData} />)} />
            <Route path='/admin/data' render={(routeProps) => (<DataExploration {...routeProps} userData={userData} />)} />
            <Route path='/admin/users' render={(routeProps) => (<ManageUser {...routeProps} userData={userData} />)} />
            <Route path='/admin/settings' render={(routeProps) => (<Settings {...routeProps} userData={userData} />)} />
            <Route path='/admin' render={(routeProps) => (<Home {...routeProps} userData={userData} />)} /> {/* Default */}
          </Switch>
        }
      </div>
      <footer>

      </footer>
    </div>
  )
}

export default withRouter(AdminContainer)
