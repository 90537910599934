import React from 'react'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { withStyles } from '@material-ui/core/styles'
import AnswerSelection from './answerSelection'

function Question ({
  classes,
  question,
  handle,
  scrollIntoView = false
}){
  const [ref, setRef] = React.useState(null)
  if(scrollIntoView && ref) {
    ref.scrollIntoView({behavior: 'smooth'})
  }
  return (
    <div
      ref={r => setRef(r)}
    >
      <Paper className={classes.container} >
        <FormControl>
          <FormLabel className={classes.groupLbl}>{question.question}</FormLabel>
          <AnswerSelection question={question} handle={handle} />
        </FormControl>
      </Paper >
    </div>
  )
}

export default withStyles(theme => ({
  container: {
    padding: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    marginTop: theme.spacing(2),
    textAlign: 'left',
  },
  groupLbl: {
    marginBottom: theme.spacing(2),
    color: 'black',
    fontSize: '1.2em',
    lineHeight: 1.5,
  },
}))(Question)
