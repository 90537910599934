import React from 'react'

import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Link,
} from '@material-ui/core'

import {makeStyles} from '@material-ui/core/styles'

import {
  Link as RouterLink,
} from 'react-router-dom'

const withStyles = makeStyles(theme => ({
  media: {
    height: theme.spacing(25),
  },
  description: {
    textAlign: 'left',
  },
}))

const dataExplorationData = [
  {
    link: '/admin/data/conferences',
    media: '/images/dashboard/heatmap.png',
    title: 'Conferences',
    description: `
    Generate customized heat maps, examine results, demographics, and see statistical
    analysis with the generated histograms.
    `,
  },
  {
    link: '/admin/data/users',
    media: 'https://via.placeholder.com/500',
    title: 'Users',
    description: `
    Explore your user data. See who has taken the assessment, explore demographics,
    and fill out information that was left out.
    `,
  },
  {
    link: '/admin/data/demographics',
    media: '/images/dashboard/map.png',
    title: 'Demographics',
    description: `
    Break test data down into demographics. Compare demographics against each other
    side by side with charts.
    `,
  },
]

function Home({userData}) {
  const classes = withStyles()
  return (
    <Grid container spacing={2}>
      {dataExplorationData.map(d => (
        <Grid item md={4} key={d.title}>
          <Card>
            <Link underline='none' component={RouterLink} to={d.link} color='inherit'>
              <CardActionArea>
                <CardMedia
                  image={d.media}
                  className={classes.media}
                  title={d.title}
                  />
                <CardContent>
                  <Typography variant='h6' color='primary'>{d.title}</Typography>
                  <Typography className={classes.description} variant='body1'>{d.description}</Typography>
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default Home
