import React, { Component, Fragment } from 'react'
import BalancePiece from './balancePiece'
import SpacerBar from '../spacerBar';
import { Typography } from '@material-ui/core'

class BalanceContainer extends Component {
  state = {}
  render() {
    return (
      <Fragment>
        <Typography variant='h4' color='textSecondary'>THE RIGHT BALANCE OF HABITS MATTERS</Typography>
        <SpacerBar />
        <BalancePiece type='connect' />
        <BalancePiece type='convey' />
        <BalancePiece type='convince' />
      </Fragment>
    )
  }
}

export default BalanceContainer