import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {axisTop, axisBottom, axisRight,axisLeft, select} from 'd3'

const axis = {
  axisTop, axisBottom, axisRight,axisLeft
}

class Axis extends React.Component {
  componentDidMount() {
    this.renderAxis()
  }
  componentDidUpdate(){
    this.renderAxis()
  }
  renderAxis() {
    const {
      orient, scale, tickFormat, ticks
    } = this.props
    const axisType = `axis${orient}`
    let axisGen = axis[axisType](scale)
    if(tickFormat) axisGen = axisGen.tickFormat(tickFormat)
    if(ticks) axisGen = axisGen.ticks(ticks)
    select(this.axisElement).call(axisGen)
  }
  render() {
    const {
      classes,
      transform,
    } = this.props
    return (<g
      className={classes.axisStyle}
      ref={el => this.axisElement = el}
      transform={transform}
      />)
  }
}

export default withStyles(theme => ({
  axisStyle: {

  },
}))(Axis)
