import React from 'react'
import {Grid} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'

import Histogram from 'visualizations/histogram'


function Hist(props) {
  const {
    data,
    color,
    title,
  } = props
  return (
    <div {...props}>
      <Histogram
        data={data}
        binCount={14}
        xAxis={{
          min: 0.2,
          max: 1,
        }}
        yAxis
        dimensions={{
          width: 350,
          height: 200,
        }}
        color={color}
        convertToPercentages
        findCdf={false}
        cdfKernelBandwidth={0.1}
        includeDownload
        title={title}
      />
    </div>
  )
}

function Container({tests, conference}) {
  const theme = useTheme()
  return (
    <Grid container spacing={2}>
      {['connect', 'convey', 'convince'].map(field => (
        <Grid item xs={4}>
          <Hist
            key={`${field}_Histogram`}
            data={tests.map(a => a[field])}
            color={theme.palette.influence[field]}
            title={`${conference}_${field}_histogram`}
            />
        </Grid>
      ))}
    </Grid>
  )
}

export default Container
