import React from 'react'

import {
  Route,
  withRouter,
} from 'react-router-dom'

import NavBar from './components/navbar.js'
import Footer from './components/footer.js'
import Home from './pages/home.js'
import LetsMeet from './pages/letsMeet.js'
import Test from './pages/test.js'
import Results from './pages/results.js'

function HomeContainer(props) {
  return (
    <div>
      <header>
        <NavBar />
      </header>
      <div style={{ marginTop: '30px', minHeight: '85vh' }}>
        <Route exact path='/' component={Home}></Route>
        <Route path='/lets_meet' component={LetsMeet}></Route>
        <Route path='/test' component={Test}></Route>
        <Route exact path='/results/:testId/:userId' component={Results}></Route>
        <Route exact path='/results/:testId' component={Results}></Route>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default withRouter(HomeContainer)
