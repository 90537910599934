import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '50px',
    height: '2px',
    margin: 'auto',
  },
}))

export default function SpacerBar({
  color,
  className,
  style,
}) {
  const classes = useStyles()
  return(
    <div style={style} className={className ? className : ''}>
      <div style={color ? {backgroundColor: color} : {}} className = {classes.root} />
    </div>
  )
}
