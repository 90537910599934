import React from 'react'

function Bins({bins, scales: {x,y}, dimensions: {height}, margins, color}) {
  return (
    <g>
      {bins.map((bin, index) => {
        const x0 = x(bin.x0)
        const x1 = x(bin.x1)
        const y0 = y(bin.length)
        return (
        <rect
          key={`bin${index}`}
          x={1}
          transform={`translate(${x0},${y0})`}
          width={x1 - x0 - 1}
          height={height - y0 - margins.bottom}
          style={{fill: color}}
          />
      )
    })}
    </g>
  )
}

export default Bins
