import React from 'react'
import Typography from '@material-ui/core/Typography';

import SpacerBar from '../../components/spacerBar.js'
import { withStyles, useTheme } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'

import {
  Redirect,
} from 'react-router-dom'

function Page ({ title, classes, children, fullWidth, loggedIn }) {
  const theme = useTheme()
  const middle = (
    <main>
      <Typography className={classes.title} variant="h4">{title}</Typography>
      <SpacerBar color={theme.palette.primary.main} className={classes.spacer} />
      {children}
    </main>
  )
  if(!loggedIn) return <Redirect to='/admin/login' />
  if(fullWidth) return middle
  return <Container>{middle}</Container>
}


export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  spacer: {
    marginBottom: theme.spacing(3),
  }
}))(Page)
