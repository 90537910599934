/*
  These are datasets for use in demographic forms
*/

import React from 'react'

import {
  Select,
  MenuItem,
} from '@material-ui/core'

export const ages = [
  { label: '18-24', value: '1' },
  { label: '25-34', value: '2' },
  { label: '35-44', value: '3' },
  { label: '45-54', value: '4' },
  { label: '55-64', value: '5' },
  { label: '65+', value: '6' },
]

export const genders = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Non-Binary / Third Gender', value: 'other' },
]

export const locations = [
  { label: 'Africa', value: 'africa' },
  { label: 'Asia', value: 'asia' },
  { label: 'Australia', value: 'australia' },
  { label: 'Europe', value: 'europe' },
  { label: 'Middle East', value: 'middle east' },
  { label: 'North America', value: 'north america' },
  { label: 'South America', value: 'south america' },
]

export const industries = [
  'Agriculture',
  'Associations',
  'Athletics',
  'Automation',
  'Automotive',
  'Construction',
  'Consumer Goods',
  'Education',
  'Energy / Utilities',
  'Engineering',
  'Finance',
  'Foundations & Non-Profits',
  'Government',
  'Health',
  'Hospitality',
  'Insurance',
  'Investor Relations',
  'Legal',
  'Manufacturing',
  'Marketing',
  'Media',
  'Pharmaceutical',
  'Publishing',
  'Real Estate',
  'Retail',
  'Sales',
  'Technology',
  'Transportation',
].map(a => ({
  label: a,
  value: a,
}))

export const titles = [
  { label: 'Owner', value: 'owner' },
  { label: 'Board Member', value: 'board member' },
  { label: 'C-Suite', value: 'c-suite' },
  { label: 'President', value: 'president' },
  { label: 'General Manager', value: 'general manager' },
  { label: 'Senior VP', value: 'senior vp' },
  { label: 'VP', value: 'vp' },
  { label: 'Director', value: 'director' },
  { label: 'Manager', value: 'manager' },
  { label: 'Supervisor', value: 'supervisor' },
  { label: 'Individual Contributor', value: 'individual contributor' },
]

export const datasets = {
  ages,
  locations,
  genders,
  industries,
  titles,
}

export const generateLookup = fields => fields.reduce((acc,field) => ({...acc, [field.value]: field.label}), {})
export const generateSelect = data => props => (
  <Select
    onChange={e => props.onChange(e.target.value)}
    value={props.value}
    >
    {data.map(option => (
      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
    ))}
  </Select>
)

export const lookup = (key, value) => {
  if(value === null || value === 'null') return 'Unknown'
  if(!(datasets && datasets[key])) return value
  let info = datasets[key].find(field => field.value === value)
  return info ? info.label : value
}

export default {
  generateLookup,
  generateSelect,
  ...datasets,
}
