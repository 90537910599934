import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {apiBase} from 'utilities/api.js'
import {toDecimal} from 'utilities/score.js'

import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import {makeStyles} from '@material-ui/core/styles'

import TestTable from './testTable.js'
import StatsOverview from './statsOverview.js'
import ConferenceVisualizationContainer from './heatmapContainer.js'

import Histograms from './histogram.js'

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(2),
  }
}))

function Report({
  onBack,
  conferences,
  jwt,
}) {
  const [isLoading, setLoading] = useState(true)
  const [tests, setTests] = useState([])

  const classes = useStyles()

  useEffect(() => {
    if(!isLoading) return
    axios.post(`${apiBase}/admin/test`, {
      conferences: conferences.map(c => c.id)
    }, {
      headers: {
        'Authorization': jwt,
      }
    })
      .then(({data} )=> {
        setTests(data.map(test => ({...test, ...toDecimal(test)}) ).sort((a,b) => b.taken > a.taken ? 1 : -1))
        setLoading(false)
      })
  }, [isLoading, conferences, jwt])

  if(isLoading) return <CircularProgress />
  return (
    <div style={{textAlign: 'left'}}>
      <Button
        variant='outlined'
        onClick={onBack}
        >Back</Button>
      <ConferenceVisualizationContainer className={classes.spacing} tests={tests} />
      <Histograms tests={tests} conference={conferences.length === 1 ? conferences[0].tag : 'Multiple'}/>
      <StatsOverview className={classes.spacing} tests={tests}/>
      <TestTable jwt={jwt} className={classes.spacing} tests={tests}
        onEdit={user => {
          let newTests = tests.slice()
          let data = newTests.filter(a => a.user_id === user.id)
          for(let i = 0; i < data.length; i++) Object.assign(data[i], [
            'name',
            'email',
            'title',
            'industry',
            'gender',
            'age',
            'location',
          ].reduce((acc,key) => {
            acc[key] = user[key]
            return acc
          },{}))
          setTests(newTests)
        }}
         />
    </div>
  )
}

export default Report
