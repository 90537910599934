import React from 'react'

import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from '@material-ui/core'

import {makeStyles} from '@material-ui/core/styles'

import Person from '@material-ui/icons/Person'
import Public from '@material-ui/icons/Public'
import LocationOn from '@material-ui/icons/LocationOn'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  title: {
    textAlign: 'center',
  }
}))

const calculateStats = (field,tests, group, icon) => {
  const generateAverage = () => {
    const totals = tests.reduce((acc,test) => {
      return {...acc,
        connect: acc.connect + test.connect,
        convey: acc.convey + test.convey,
        convince: acc.convince + test.convince,
      }
    }, {
      name: 'Averages',
      group: field,
      icon,
      count: tests.length,
      connect: 0,
      convey: 0,
      convince: 0,
    })
    totals.connect = Math.round(totals.connect / tests.length * 1000) / 10
    totals.convey = Math.round(totals.convey / tests.length * 1000) / 10
    totals.convince = Math.round(totals.convince / tests.length * 1000) / 10
    return totals
  }
  const generatePrimary = () => {
    const totals = tests.reduce((acc,test) => {
      if(test.connect === test.convey && test.connect === test.convince) {
        acc.connect++
        acc.convey++
        acc.convince++
      }else if(test.connect === test.convey && test.connect > test.convince) {
        acc.connect++
        acc.convey++
      } else if (test.convey === test.convince && test.convey > test.connect) {
        acc.convey++
        acc.convince++
      } else if (test.convince === test.connect && test.convince > test.convey) {
        acc.connect++
        acc.convince++
      } else if(test.connect > test.convey && test.connect > test.convince) {
        acc.connect++
      } else if(test.convey > test.convince) {
        acc.convey++
      } else {
        acc.convince++
      }
      return acc
    }, {
      name: `Primary Strengths`,
      group: field,
      icon,
      count: tests.length,
      connect: 0,
      convey: 0,
      convince: 0,
    })
    const total = totals.connect + totals.convey + totals.convince
    totals.connect = Math.round(totals.connect / total * 1000) / 10 + '%'
    totals.convey = Math.round(totals.convey / total * 1000) / 10 + '%'
    totals.convince = Math.round(totals.convince / total * 1000) / 10 + '%'
    return totals
  }
  return [generatePrimary(), generateAverage()]
}

function titleCase(str) {
   let splitStr = str.toLowerCase().split(' ');
   for (let i = 0; i < splitStr.length; i++) splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
   return splitStr.join(' ');
}

function Overview({tests, className}) {
  const classes = useStyles()

  const generateDemographicStats = (field, group, icon) => [...(new Set(tests.map(a => a[field])))]
    .filter(a => a)
    .map(key => ({
      group,
      icon,
      field: (key ? key : 'Unknown'),
      filter: test => test[field] === key,
    }))
  const stats = [
    {field: 'Total', filter: () => true, group: '', icon: <Public />},
  ]
    .concat(generateDemographicStats('gender', 'Gender', <Person />))
    .concat(generateDemographicStats('location', 'Location', <LocationOn />))
    .reduce((acc, stats) => {
      const filtered = tests.filter(stats.filter)
      if(filtered.length === 0) return acc
      return acc.concat(calculateStats(stats.field, filtered, stats.group, stats.icon))
    },[])
  return (
    <div className={className}>
      <Paper className={classes.paper}>
        <Typography className={classes.title} variant='h6'>Aggregate Statistics</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Demographic Group</TableCell>
              <TableCell>Statistic</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Connect</TableCell>
              <TableCell>Convey</TableCell>
              <TableCell>Convince</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.map((stat, index) => (
              <TableRow key={`stats_index_${index}`}>
                <TableCell>
                  <Grid spacing={4} container alignItems='center'>
                    <Grid item>{stat.icon}</Grid>
                    <Grid item>{titleCase(stat.group)}</Grid>
                  </Grid>
                </TableCell>
                <TableCell>{stat.name}</TableCell>
                <TableCell>{stat.count}</TableCell>
                <TableCell>{stat.connect}</TableCell>
                <TableCell>{stat.convey}</TableCell>
                <TableCell>{stat.convince}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>

  )
}

export default Overview
