import React, {useState, useEffect} from 'react'

import {
  arc,
  scaleLinear,
  pie,
  scaleBand,
} from 'd3'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import SVGRef from 'visualizations/shared/SVGRef'

function VisualizationController(props) {
  const {tests} = props
  const results = tests.reduce((results, test) => {
    if(test.connect === test.convey && test.connect === test.convince) {
      results.connectConvey.push(test)
    }else if(test.connect === test.convey && test.connect > test.convince) {
      results.connectConvey.push(test)
    } else if (test.convey === test.convince && test.convey > test.connect) {
      results.conveyConvince.push(test)
    } else if (test.convince === test.connect && test.convince > test.convey) {
      results.convinceConnect.push(test)
    } else if(test.connect > test.convey && test.connect > test.convince) {
      results.connect.push(test)
    } else if(test.convey > test.convince) {
      results.convey.push(test)
    } else {
      results.convince.push(test)
    }
    return results
  },{
    connect: [],
    convey: [],
    convince: [],
    connectConvey: [],
    conveyConvince: [],
    convinceConnect: [],
  })
  return <Visualization {...props} results={results} />
}

const downloadQuality = 800 // pixels
const sizeOffset = 40 // pixels
const useVisualizationStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },

}))


const getId = function*() {
  let id = 0
  yield ++id
}

function Visualization({
  size,
  title = Math.floor(Math.random() * 100000),
  results = [],
}) {
  const [downloadLink, setdownloadLink] = useState(false)
  const [currentSize, setCurrentSize] = useState(downloadQuality)
  const [svg, setSvg] = useState(null)
  const classes = useVisualizationStyles()
  const theme = useTheme()

  const COLORS = {
    connect: '#38939d',
    convey: '#f3ab35',
    convince: '#c62822',
  }
  useEffect(() => {
    if(downloadLink || !svg) return
    const html = svg.outerHTML.replace(/NS\d+:href/gi, 'xlink:href')
    const imgsrc = 'data:image/svg+xml;base64,'+ btoa(html)
    const img = new Image()
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.naturalWidth
      canvas.height = img.naturalHeight
      canvas.style.backgroundColor = 'rgba(0,0,0,0)'
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0,0)
      console.log('IMAGE SAVED WITH SIZE:', `${canvas.width}x${canvas.height}`)
      setdownloadLink(canvas.toDataURL('image/png'))
      setCurrentSize(size)
    }
    img.src = imgsrc
  },[svg,currentSize,downloadLink, size])

  const scaleFactor = currentSize / downloadQuality
  const finalCircleOuterRadius = scaleFactor * 300
  const arcGenerator = arc()
  .outerRadius(currentSize / 2)
  .innerRadius(125 * scaleFactor)

  const textArc = arc().innerRadius(finalCircleOuterRadius + 27 * scaleFactor).outerRadius(finalCircleOuterRadius + 27 * scaleFactor)

  const id = getId().next().value

  const pieData = pie().sort(null).value(d => d.val)([
    {type: 'connect', val: results.connect.length + results.connectConvey.length + results.convinceConnect.length,},
    {type: 'connect', val: results.convey.length + results.connectConvey.length + results.conveyConvince.length,},
    {type: 'convince', val: results.convince.length + results.convinceConnect.length + results.conveyConvince.length,},
  ])

  const rScale = scaleLinear().domain([0,1]).range([0, 290 * scaleFactor])

  const bandMargin = 0.2 * scaleFactor
  const bandScales = {
    connect: scaleBand().domain(results.connect.map(a => a.id)).range([pieData[0].startAngle + bandMargin, pieData[0].endAngle - bandMargin]),
    convey: scaleBand().domain(results.convey.map(a => a.id)).range([pieData[1].startAngle + bandMargin, pieData[1].endAngle - bandMargin]),
    convince: scaleBand().domain(results.convince.map(a => a.id)).range([pieData[2].startAngle + bandMargin, pieData[2].endAngle - bandMargin]),
  }

  const makeTriangle = (x,y) => (
    <path
      fill='#CCCCCC'
      d={`M${x - 4 * scaleFactor} ${y - 25* scaleFactor}L${x + 4* scaleFactor} ${y - 25* scaleFactor}L${x} ${y}Z`}
      />
  )

  const makePin = field => test => {
    const x = (rScale(test[field]) * Math.cos(bandScales[field](test.id) - (Math.PI / 2)))
    const y = (rScale(test[field]) * Math.sin(bandScales[field](test.id) - (Math.PI / 2)))
    return (
      <g className='pin' key={`${field}_pin_${test.id}`}>
        {makeTriangle(x,y)}
        <circle
          fill='white'
          cx={x}
          cy={y - 25 * scaleFactor}
          r={8 * scaleFactor}
          />
      </g>
    )
  }

  const makeTiePin = (field, index) => test => {
    const x = rScale(test[field]) * Math.cos(pieData[index].endAngle - (Math.PI / 2))
    const y = rScale(test[field]) * Math.sin(pieData[index].endAngle - (Math.PI / 2))
    return (
      <g className={'pin ' + field} key={`${field}_tiePin_${index}`}>
        {makeTriangle(x,y)}
        <circle
          fill='white'
          cx={x}
          cy={y - 25 * scaleFactor}
          r={8 * scaleFactor}
          />
      </g>
    )
  }

  const makeTextPath = (text, index) => {
    const pathId = `${text}_Path_${id}`
    const middle = (pieData[index].startAngle + pieData[index].endAngle) / 2
    if(middle === pieData[index].startAngle) return <g />
    return (
      <g>
        <path
          id={pathId}
          fillOpacity={0}
          d={textArc.startAngle(middle - Math.PI).endAngle(middle + Math.PI)()}
          />
        <text
          letterSpacing={3}
          fontWeight={500}
          fontFamily='"Roboto","Helvetica",sans-serif'
          fontSize={60 * scaleFactor}
          >
          <textPath
            xlinkHref={`#${pathId}`}
            startOffset='25%'
            textAnchor='middle'
            fill={'white'}
            >
            {text}
          </textPath>
        </text>
      </g>
    )
  }
  return (
    <Paper className={classes.paper}>
      <Typography variant='h6'>{title}</Typography>
      {downloadLink === false && <CircularProgress />}
      <div style={{display: downloadLink ? 'block' : 'none', backgroundColor: 'none'}}>
        <SVGRef
          height={currentSize + sizeOffset}
          width={currentSize + sizeOffset}
          setRef={setSvg}
          style={{
            margin: 'auto',
            display: 'block',
            backgroundColor: 'none',
          }}
          shouldTransform
          >
          <g>
            <path
              d={arcGenerator(pieData[0])}
              fill={COLORS.connect}
              />
            <path
              d={arcGenerator(pieData[1])}
              fill={COLORS.convey}
              />
            <path
              d={arcGenerator(pieData[2])}
              fill={COLORS.convince}
              />
          </g>
          <g>
            <circle
              r={finalCircleOuterRadius}
              stroke='white'
              strokeWidth={3 * scaleFactor}
              fillOpacity={0}
              />
          </g>
          <g>
            {results.connect.map(makePin('connect'))}
            {results.convey.map(makePin('convey'))}
            {results.convince.map(makePin('convince'))}
            {results.connectConvey.map(makeTiePin('connect', 0))}
            {results.conveyConvince.map(makeTiePin('convey', 1))}
            {results.convinceConnect.map(makeTiePin('convince', 2))}
          </g>
          <g>
            {makeTextPath('Connect', 0, theme.palette.influence.connect)}
            {makeTextPath('Convey', 1, theme.palette.influence.convey)}
            {makeTextPath('Convince', 2, theme.palette.influence.convince)}
          </g>
        </SVGRef>
      </div>
      <Button
        href={downloadLink ? downloadLink : undefined}
        download={`${title}.png`}
        >
        Download
      </Button>
    </Paper>
  )
}

export default VisualizationController
