import React from 'react'

import NavBar from '../../components/navbar'

import Home from '@material-ui/icons/Home'
import Person from '@material-ui/icons/Person'
import Share from '@material-ui/icons/Share'
import Data from '@material-ui/icons/DataUsage'
import Settings from '@material-ui/icons/Settings'
import ExitToApp from '@material-ui/icons/ExitToApp'

// TODO: Need to filter the tabs by the features they have
const features = [
  {
    text: 'Home',
    link: '/admin',
    icon: (<Home />),
    feature: 'all'
  },
  {
    text: 'Manage Conferences',
    link: '/admin/conferences',
    icon: (<Share />),
    feature: 'conference',
  },
  {
    text: 'Data Exploration',
    link: '/admin/data',
    icon: (<Data />),
    feature: 'report',
  },
  {
    text: 'Manage Users',
    link: '/admin/users',
    icon: (<Person />),
    feature: 'manage',
  },
  {
    text: 'Account Settings',
    link: '/admin/settings',
    icon: (<Settings />),
    feature: 'all',
  },
]

function AdminNavBar({
  allowedFeatures = new Set(['all']),
  onLogout = () => {},
}) {
  return <NavBar
    baseUrl='/admin'
    links={features.filter(feature => allowedFeatures.has(feature.feature))}
    actions={[
      {
        text: 'Logout',
        onClick: onLogout,
        icon: (<ExitToApp />)
      }
    ]}
    />
}

export default AdminNavBar
