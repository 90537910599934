import React, {useState} from 'react'
import Table from 'components/table.js'
import UserModal from './userModal.js'

import {makeStyles} from '@material-ui/core/styles'

const resultStyle = {
  fontWeight: 500,
  display: 'block',
  width: '100%',
}
const useStyles = makeStyles(theme => ({
  connect: {...resultStyle,
    color: theme.palette.influence.connect,
  },
  convey: {...resultStyle,
    color: theme.palette.influence.convey,
  },
  convince: {...resultStyle,
    color: theme.palette.influence.convince,
  },
}))


function TestTable({
  tests,
  className = '',
  styles = {},
  onEdit,
  jwt,
}) {
  const [userModal, setUserModal] = useState({
    open: false,
    user: null,
  })

  const classes = useStyles()
  const parentData = {}
  const parentSet = new Set()
  tests.forEach(test => {
    if(!parentData.hasOwnProperty(test.user_id)) {
      parentData[test.user_id] = []
    } else {
      parentSet.add(test.user_id)
    }
    parentData[test.user_id].push(test)
  })
  const parents = [...new Set(tests.map(a => a.user_id))].filter(a => parentSet.has(a)).map(userId => {
    let userTests = parentData[userId].sort((a,b) => {
      const first = new Date(a.taken)
      const second = new Date(b.taken)
      if(first > second) return 1
      if(second > first) return -1
      return 0
    })
    const firstTest = userTests[0]
    const lastTest = userTests[userTests.length - 1]
    return {...lastTest,
      containerId: userId,
      isParent: true,
      user_id: null,
      connect: lastTest.connect - firstTest.connect,
      convey: lastTest.convey - firstTest.convey,
      convince: lastTest.convince - firstTest.convince,
    }

  })
  const testData = parents.concat(tests)
  const formatResults = field => props => {
    const val = Math.round(props[field] * 100)
    if(props.isParent) {
      const sign = val < 0 ? '-' : '+'
      return <span className={classes[field]}>{sign}{Math.abs(val)}</span>
    }
    return <span className={classes[field]} style={{textAlign: 'right'}}>{val}%</span>
  }

  const userModalOnClick = user => () => setUserModal({open: true, user})
  const userModalOnClose = () => setUserModal({open: false, user: userModal.user})

  return (
    <div className={className} styles={styles}>
      <Table
        title={'Test Results'}
        data={testData}
        parentChildData={(row, rows) => rows.find(a => a.containerId === row.user_id)}
        columns={[
          {title:'Name', field:'name', render: props => (<span onClick={userModalOnClick(props)}>{parentSet.has(props.user_id) && !props.isParent ? '' : props.name}</span>)},
          {title:'Taken', field:'taken', render: props => (<span>{(new Date(props.taken)).toLocaleDateString()}</span>)},
          {title:'Connect', field:'connect', render: formatResults('connect')},
          {title:'Convey', field:'convey', render: formatResults('convey')},
          {title:'Convince', field:'convince', render: formatResults('convince')},
        ]}
        options={{
          sorting: true,
        }}
        editable={{isEditable: () => false}}
        />
      <UserModal
        user={userModal.user}
        open={userModal.open}
        onClose={userModalOnClose}
        onUserUpdate={onEdit}
        jwt={jwt}
        />
    </div>
  )
}

export default TestTable
