import React from 'react'

import {
  Link,
} from 'react-router-dom'

import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'

import {
  makeStyles
} from '@material-ui/core/styles'

import Page from '../components/page.js'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  overview: {
    marginTop: theme.spacing(2),
  }
}))

function Home({
  userData,
}) {
  const classes = useStyles()
  return (
    <Page title='Welcome to the Influence360 Dashboard' loggedIn={userData.loggedIn}>
      <Grid container spacing={2} className={classes.overview}>
        <Grid item md={4}><Paper className={classes.paper}>
          <Typography variant='h6'>Total Test Takers</Typography>
        </Paper></Grid>
      <Grid item md={4}><Link to='/admin/conferences'><Paper className={classes.paper}>
          <Typography variant='h6'>Manage Conferences</Typography>
        </Paper></Link></Grid>
        <Grid item md={4}><Paper className={classes.paper}>
          <Typography variant='h6'>Other Fast Stats</Typography>
        </Paper></Grid>
      </Grid>

    </Page>
  )
}

export default Home
