import React, {useState} from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider';


import MenuIcon from '@material-ui/icons/Menu'

import {Link} from 'react-router-dom'


function NavBar ({
  classes,
  links = [],
  actions = [],
  baseUrl = '/',
}) {
  const [displayDrawer, setDisplayDrawer] = useState(false)
  const toggleDrawer = () => setDisplayDrawer(!displayDrawer)
  const showDrawer = links.length > 0 || actions.length > 0
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <Link to={baseUrl}>
            <img
              alt='logo'
              className={classes.logo}
              src='https://www.influence360.com/wp-content/uploads/2016/10/influence360-logo.png'
              />
          </Link>
          <div className={classes.center}></div>
          {showDrawer && (
            <IconButton onClick={toggleDrawer} edge="start" color="primary" aria-label="Menu">
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {showDrawer &&
        <Drawer open={displayDrawer} anchor='right' onClose={toggleDrawer}>
        <div role="presentation" onClick={toggleDrawer}>
          <List>
            {links.map(info => {
              const link = React.forwardRef((props, ref) => (
                <Link innerRef={ref} to={info.link} {...props} />
              ))
              return (
                <ListItem button component={link} key={info.text}>
                  {info.icon && <ListItemIcon>{info.icon}</ListItemIcon>}
                  <ListItemText primary={info.text} />
                </ListItem>
              )
            })}
            {links.length > 0 && actions.length > 0 &&
              <Divider />
            }
            {actions.length > 0 &&
              actions.map(action => (
                <ListItem button onClick={action.onClick} key={action.text}>
                  {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                  <ListItemText primary={action.text} />
                </ListItem>
              ))
            }
          </List>
        </div>
      </Drawer>
    }
    </div>
  )
}


export default withStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.others.white,
  },
  logo: {
    flexGrow: 0,
    height: '65.28px',
  },
  center: {
    flexGrow: 1,
  }
}))(NavBar)
