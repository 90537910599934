export const maxes = {
  connect: 31.52,
  convey: 65.27,
  convince: 41.988,
}

export const toDecimal = ({connect,convey,convince}) => ({
  connect: connect / maxes.connect,
  convey: convey / maxes.convey,
  convince: convince / maxes.convince,
})

export const toPercentages = ({connect,convey,convince}) => ({
  connect: Math.round(connect / maxes.connect * 100) + '%',
  convey: Math.round(convey / maxes.convey * 100) + '%',
  convince: Math.round(convince / maxes.convince * 100) + '%',
})
