import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Grid } from '@material-ui/core';

import Filter from './filter'
import Histogram from '../conferences/report/histogram'
import { toDecimal } from 'utilities/score.js'


const demographicGroups = [
  { title: 'Gender', key: 'gender', dataset: 'genders' },
  { title: 'Location', key: 'location', dataset: 'locations' },
  { title: 'Age', key: 'age', dataset: 'ages' },
]

const initialKeys = () => demographicGroups.reduce((acc, test) => {
  acc[test.key] = new Set()
  return acc
}, {})

function Demographics({ userData: { jwt } }) {
  const [results, setResults] = useState([])
  const [filterOptions, setFilterOptions] = useState(initialKeys())
  const [filter1, setFilter1] = useState(initialKeys())
  const [filter2, setFilter2] = useState(initialKeys())

  useEffect(() => {
    axios.get('/api/admin/demographics', {
      headers: {
        "Authorization": jwt,
      }
    })
      .then(({ data }) => {
        if (!Array.isArray(data)) return
        setResults(data.map(test => ({...test, ...toDecimal(test)})))
        const filterOptions = data.reduce((acc, test) => {
          demographicGroups.forEach(({ key }) => acc[key].add(test[key]))
          return acc
        }, initialKeys())
        setFilterOptions(filterOptions)
      })
  }, [jwt])

  const [group1, group2] = results.reduce(([group1,group2], test) => {
    const checkIfPasses = (filter, test) => demographicGroups.reduce(
      (success, { key }) => success && (filter[key].size === 0 || filter[key].has(test[key]))
      , true)
      if(checkIfPasses(filter1, test)) group1.push(test)
      if(checkIfPasses(filter2, test)) group2.push(test)
    return [group1, group2]
  },[[],[]])

  console.log(group1, group2)

  return (
    <>
      <Filter
        activeFilters={filter1}
        setFilters={setFilter1}
        filterGroups={demographicGroups}
        filterOptions={filterOptions}
        activeOnBottom
      />
      <Histogram
        tests={group1}
      />
      <Histogram
        tests={group2}
      />
      <Filter
        activeFilters={filter2}
        setFilters={setFilter2}
        filterGroups={demographicGroups}
        filterOptions={filterOptions}
      />
      <Grid container>
        <Grid item sm={6}>
          
        </Grid>
        <Grid item sm={6}>
          
        </Grid>
      </Grid>
      <div style={{height: '10vh'}} />
    </>
  )
}

export default Demographics
