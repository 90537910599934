import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Tip from './tip'

function TipSection({
  classes,
  tips
}) {
  if(tips.length === 0) return <div />
  return (
    <Box className={classes.container}>
      <Typography className={classes.text} variant='h4' color='textSecondary'>
        How You Can Improve Your Influence Profile
      </Typography>
      <Typography className={classes.text} variant='body1' color='textPrimary' align='left'>
        You can strengthen your skills and improve your Influence Profile with a little work and practice. Get started with these tips!
      </Typography>
      {tips.map((tip, index) => { return <Tip value={tip} key={index} className={classes.tip} /> })}
      </Box>

    )
  }


  export default withStyles(theme => ({
    text: {
      lineHeight: 2
    },
    container: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(3),
      textAlign: 'left',
    },
  }))(TipSection)
