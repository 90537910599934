import React from 'react'

import {
  AppBar,
  Toolbar,
  Dialog,
  IconButton,
  Slide,
  Typography,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import UserTable from '../../components/userTable'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

function UserModal({
  open,
  onClose,
  user,
  jwt,
  onUserUpdate,
}) {
  const title = user ? user.name : 'No User Found'
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
      >
      <AppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant='h6'>{title}</Typography>
        </Toolbar>
      </AppBar>
      <UserTable 
        title={title}
        jwt={jwt}
        data={[user]}
        getUpdateUserData={newData => ({ ...newData, id: newData.isParent ? newData.containerId : newData.user_id})}
        onUpdated={userData => {
          onUserUpdate(userData)
          setTimeout(onClose, 10)
        }}
    />
    </Dialog>
  )
}

export default UserModal
