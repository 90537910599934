import React from 'react'
import { Chip, Divider, makeStyles } from '@material-ui/core'

import { lookup } from 'datasets'

const useStyles = makeStyles(theme => ({divider: {
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}}))

const Filter = ({
  title,
  activeFilters,
  setFilters,
  filterGroups,
  filterOptions,
  activeOnBottom = false,
}) => {
  const classes = useStyles()

  const addFilter = (key, filter) => () => {
    let copy = {...activeFilters}
    copy[key].add(filter)
    setFilters(copy)
  }
  const removeFilter = (key, filter) => () => {
    let copy = { ...activeFilters }
    copy[key].delete(filter)
    setFilters(copy)
  }

  const active = (
    <div>
      {filterGroups.map(({ key, dataset }) => [...activeFilters[key]].map(filter => (
        <Chip
          label={lookup(dataset, "" + filter)}
          onDelete={removeFilter(key, filter)}
        />
      )))}
    </div>
  )

  const inactive = (
    <div>
      {filterGroups.map(({ title, key, dataset }) => (
        <div>
          <p>{title}</p>
          <div>
            {
              [...filterOptions[key]]
                .filter(a => !activeFilters[key].has(a))
                .map(filter => (
                  <Chip
                    label={lookup(dataset, "" + filter)}
                    onClick={addFilter(key, filter)}
                  />
                ))
            }
          </div>
        </div>
      ))}
    </div>
  )

  if(activeOnBottom) {
    return (
      <>
      {inactive}
      < Divider className={classes.divider} />      
      {active}
      </>
    )
  }

  return (
    <>
      {active}
      < Divider className={classes.divider} />
      {inactive}
    </>
  )
}

export default Filter