const types = require('./resultTypes')
module.exports = function CalcResultTypes(scores) {
  let connect, convince, convey
  if (scores.connect >= 0.82) {
    connect = types.STRONG
  } else if (scores.connect <= 0.35) {
    connect = types.WEAK
  } else {
    connect = types.AVERAGE
  }
  if (scores.convey >= 0.9) {
    convince = types.STRONG
  } else if (scores.convey <= 0.29) {
    convince = types.WEAK
  } else {
    convince = types.AVERAGE
  }
  if (scores.convince >= 0.9) {
    convey = types.STRONG
  } else if (scores.convince <= 0.31) {
    convey = types.WEAK
  } else {
    convey = types.AVERAGE
  }
  return {
    connect: connect,
    convince: convince,
    convey: convey,
  }

}
