import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { withStyles, useTheme } from '@material-ui/core/styles'
import CircleBar from '../../../visualizations/circleBar'


function PieContainer({classes, scores}) {
  const {
    palette: {
      influence: {
        connect,
        convey,
        convince,
      }
    }
  } = useTheme()
  return (
    <Box className={classes.container}>
      <Grid container alignItems={'center'} spacing={3}>
        <Grid item md={4}>
          <CircleBar color={connect} value={scores.connect} animated />
        </Grid>

        <Grid item md={4}>
          <CircleBar color={convey} value={scores.convey} animated />
        </Grid>

        <Grid item md={4}>
          <CircleBar color={convince} value={scores.convince} animated />
        </Grid>

      </Grid>
      <Typography align='left' className={classes.text} variant='h4'>What do my scores mean?</Typography>
      <Typography align='left' className={classes.text} variant='body1'>The optimal score is 100% in each of the three habits. The higher the score, the more you demonstrate mastery of the influence habit.</Typography>
    </Box>
  );
}


export default withStyles(theme => ({
  text: {
    marginTop: theme.spacing(3)
  },
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  }
}))(PieContainer)
