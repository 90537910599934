import React, { Component, Fragment } from 'react'
import { Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

class BalancePiece extends Component {
  render() {
    const { classes, type } = this.props
    let info = this.getInfo(type)
    return (
      <Box className={classes.container}>
        <img src={info.src} alt={`${type} graph`} className={classes.img} />
        <Typography variant='body1' className={classes.text}>
          {info.intro}
        </Typography>
        {this.getSections().map((header, index) => {
          return (
            <Fragment key={index}>
              <Typography variant='body1' className={classes.text}>
                <strong>{header.strong}</strong> {info[header.key]}
              </Typography>
            </Fragment>
          )
        })}
      </Box>
    )
  }

  getInfo(type) {
    switch (type) {
      case 'connect':
        return {
          intro: 'Connecting measures your social agility and empathy. You make it a priority to find common ground, respect others’ perspectives, and are mindful of the nuances of social interaction.',
          src: 'https://www.influence360.com/wp-content/uploads/2016/10/scale-connect.png',
          why: 'People are likely to actively engage and make it a priority to interact with you. They tend to be more open with you and less guarded about their feelings.',
          traits: 'Respectful, genuine, engaging.',
          weak: 'People may feel that you don’t respect their perspectives. They may respond by dodging, delaying, or outright ignoring your attempts to interact with them.',
          overuse: 'If you rely solely on connecting, people may label you as “all talk and no action.“',
        }
      case 'convey':
        return {
          intro: 'Conveying measures your clarity and ability to frame messages. You simplify to amplify even the most complex information — without dumbing it down.',
          src: 'https://www.influence360.com/wp-content/uploads/2016/10/scale-convey.png',
          why: 'If content is king, context is God. People gain a clear understanding of your message and are able to retain the key points. They are able to pass along accurate information to others.',
          traits: 'Credible, articulate, conscientious.',
          weak: 'When you confuse, you lose. Doubt, skepticism, and misunderstandings increase.',
          overuse: 'If you rely solely on delivering information, but without the proper framework and context, people may label you as hard to follow and confusing.',
        }
      case 'convince':
        return {
          intro: 'Convincing measures how well you earn other people’s trust. You change minds and gain others’ lasting commitment, not merely their short-term compliance.',
          src: 'https://www.influence360.com/wp-content/uploads/2016/10/scale-convince.png',
          why: 'People willingly follow your lead. They see you as a person who walks the talk, so they proactively seek your input and enlist others to take action, too.',
          traits: 'Empowering, decisive, proactive.',
          weak: 'People may feel that you are either apathetic or overpowering. They may view you as lacking passion or dominating. They’ll comply if necessary, but they’ll withhold information from you and turn to the rumor mill.',
          overuse: 'If you rely solely on convincing, people may feel that you’re trying to dominate or control them. They may label you as a manipulator.',
        }
      default:
        return
    }
  }

  getSections() {
    return [
      {
        key: 'why',
        strong: 'Why this habit helps you gain influence: '
      },
      {
        key: 'traits',
        strong: 'Traits associated with this habit: '
      },
      {
        key: 'weak',
        strong: 'What happens when you’re weak at this habit? '
      },
      {
        key: 'overuse',
        strong: 'What happens if you overuse this habit? '
      },
    ]
  }
}

export default withStyles(theme => ({
  img: {
    maxWidth: '100%'
  },
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    textAlign: 'left',
    backgroundColor: '#f1f1f2', //light gray matching the pic bgcolor
  },
  text: {
    lineHeight: 2,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))(BalancePiece)