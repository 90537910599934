import React from 'react'

import {
  Switch,
  Route,
} from 'react-router-dom'

import Page from '../../components/page.js'
import Conferences from './conferences'
import Home from './home'
import User from './users'
import Demographics from './demographics'

function DataExploration({userData}) {
  return (
    <Page title='Data Exploration' loggedIn={userData.loggedIn}>
      <Switch>
        <Route path='/admin/data/users' render={(r) => <User {...r} userData={userData} />} />
        <Route path='/admin/data/conferences' render={(r) => <Conferences {...r} userData={userData} />} />
        <Route path='/admin/data/demographics' render={(r) => <Demographics {...r} userData={userData} />} />
        <Route path='/admin/data' render={(routeProps) => (<Home {...routeProps} userData={userData}/>)}/>
      </Switch>
    </Page>
  )
}

export default DataExploration
