import React, { Component } from 'react'
import { Container } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

class WhiteBackground extends Component {
  state = {}
  render() {
    const { classes, children } = this.props
    return (
      <main className={classes.container}>
        <Container>
          {children}
        </Container>
      </main>
    )
  }
}

export default withStyles(theme => ({
  container: {
    backgroundColor: 'white',
  },
}))(WhiteBackground)