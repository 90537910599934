import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {getJwt, apiBase} from '../../utilities/api.js'

import {toDecimal} from '../../utilities/score.js'

import { Typography, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  Redirect,
} from 'react-router-dom'

import Page from '../components/page'
import Overview from '../components/results/overview'
import PieContainer from '../components/results/pieContainer'
import TipSection from '../components/results/tipSection'
import Summary from '../components/results/summary'
import WavyPic from '../components/results/wavyPic.js'
import BalanceContainer from '../components/results/balanceContainer'
import WhiteBackground from '../components/whiteBackground'


function Results({classes, match: {params: {testId, userId = -1}}}) {
  const [isLoading, setLoading] = useState(true)
  const [goHome, setGoHome] = useState(false)
  const [scores,setScores] = useState({connect: 0, convey: 0, convince: 0})
  const [tips, setTips] = useState([])
  useEffect(() => {
    if(!isLoading) return alert('Please be sure to save your results by clicking ctrl/cmd + p, then choose "Save as a PDF" from the drop down menu.')
    const onData = ({ data }) => {
      console.log(data)
      setLoading(false)
      setTips(data.tips)
      setScores(toDecimal(data))
    }

    axios.get(`${apiBase}/user/test/${testId}`, {
      headers: {
        'Authorization': getJwt(),
      }
    })
      .then(onData)
      .catch(() => {
        console.log('caught', userId, testId)
        axios.get(`/utils/user/${userId}/test/${testId}`)
          .then(onData)
          .catch((e) => console.log(e) && setGoHome(true))
      })
  }, [isLoading, testId, userId])

  if(!getJwt() || goHome) return <Redirect to='/' />
  if(isLoading) return <CircularProgress />
  return (
    <div>
      <Page title='My Influence360 Results'>
        <PieContainer scores={scores} />
      </Page>
      <WhiteBackground>
        <Overview scores={scores} />
        <hr />
        <TipSection tips={tips} />
        <Summary scores={scores} />
        <WavyPic scores={scores} />
        <hr className={classes.hr} />
        <BalanceContainer />
        <Typography variant='body2' className={classes.text}><em>The Influence Profile has been validated by the Social Research Lab at the University of Northern Colorado.</em></Typography>
      </WhiteBackground>
    </div >

  )
}

export default withStyles(theme => ({
  button: {
    align: 'left'
  },
  hr: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  text: {
    lineHeight: 2,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'left',
  },
}))(Results)
