import React, { useState, useEffect } from 'react'
import { histogram, scaleLinear, min, max } from 'd3'

import Axes from './axes'
import Bins from './bins'
import CDF from './cdf'
import SVGRef from '../shared/SVGRef'
import { Button } from '@material-ui/core'

function Histogram(props) {
  const {
    dimensions,
    binCount,
    xAxis,
    yAxis,
    data,
    color,
    convertToPercentages,
    findCdf = false,
    cdfKernelBandwidth,
    includeDownload = false,
    title = 'Histogram'
  } = props
  const {
    height,
    width,
  } = dimensions
  const [svg,setSVG] = useState(null)
  const [downloadLink, setDownloadLink] = useState(null)
  useEffect(() => {
    if(!includeDownload || downloadLink || !svg) return
    const i = new Image()
    i.onload = () => {
      const c = document.createElement('canvas')
      c.width = i.naturalWidth
      c.height = i.naturalHeight
      c.style.backgroundColor = 'rgba(0,0,0,0)'
      c.getContext('2d').drawImage(i,0,0)
      setDownloadLink(c.toDataURL('image/png'))
    }
    i.src = `data:image/svg+xml;base64,${btoa(svg.outerHTML.replace(/NS\d+:href/gi, 'xlink:href'))}`
  }, [svg, downloadLink, includeDownload])
  const scales = {}
  const margins = { top: 20, right: 20, bottom: 20, left: 40 }


  const getAxisScale = (axis, lowRange, hiRange, data) => {
    const lowBound = axis.min === 'auto' ? min(data) : axis.min
    const hiBound = axis.max === 'auto' ? max(data) : axis.max
    return scaleLinear()
      .domain([lowBound, hiBound])
      .range([lowRange, hiRange])
  }

  if (xAxis) {
    scales.x = getAxisScale(xAxis, margins.left, width - margins.right, data)
  }

  const bins = histogram()
    .domain(scales.x.domain())
    .thresholds(scales.x.ticks(binCount))(data)

  if (yAxis) {
    scales.y = scaleLinear()
      .domain([0, max(bins, function (d) { return d.length; })])
      .range([height - margins.bottom, margins.top])
  }

  return (
    <div {...props}>
      <SVGRef
        height={height}
        width={width}
        setRef={setSVG}
      >
        <Axes
          scales={scales}
          dimensions={dimensions}
          margins={margins}
          convertToPercentages={convertToPercentages}
        />
        <Bins
          bins={bins}
          scales={scales}
          dimensions={dimensions}
          margins={margins}
          color={color}
        />
        {findCdf && (
          <CDF
            scales={scales}
            bandwidth={cdfKernelBandwidth}
            data={data}
            transform={`translate(0,0)`}
          />
        )}
      </SVGRef>
      {includeDownload && <Button download={`${title}.png`} href={downloadLink ? downloadLink : undefined}>Download</Button>}
    </div>
  )
}

export default React.memo(Histogram)
