import React, { Component } from 'react'
import { Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CalcResultTypes from './calcResultTypes'
const types = require('./resultTypes')

class Overview extends Component {
  render() {
    const { scores, classes } = this.props
    let results = CalcResultTypes(scores)
    return (
      <Box className={classes.container}>
        {Object.keys(results).map((key, index) => this.generateOverview(key, results[key], index))}
      </Box>
    )
  }

  generateOverview(key, type, index) {
    const { classes } = this.props

    switch (key) {
      case 'connect':
        if (type === types.WEAK) {
          return (
            <div key={index} className={classes.section}>
              <Typography className={classes.text} variant="h5">Connect Is Your Untapped Habit</Typography>
              <Typography className={classes.text} variant='body1'>Whether it’s true or not, people may feel that you disregard their feelings and don’t care about their perspectives. As a result, they may undercut your attempts to interact with them.</Typography>
            </div>
          )
        } else if (type === types.AVERAGE) {
          return (
            <div key={index} className={classes.section}>
              <Typography className={classes.text} variant="h5">Connect Is Your Occasional Habit</Typography>
              <Typography className={classes.text} variant='body1'>You connect well with people who share your experiences, world views, or temperament. However, people who are not like-minded may have a hard time relating to you and choose to disengage. Make it a habit to find common ground with everyone by carefully considering the other person’s values and perspectives before you communicate.</Typography>
            </div>
          )
        } else {
          return (
            <div key={index} className={classes.section}>
              <Typography className={classes.text} variant="h5">Connect Is Your Dominant Habit</Typography>
              <Typography className={classes.text} variant='body1'>Congratulations! You make it a habit to put yourself in others’ shoes. You routinely identify barriers and common ground and, as a result, others feel that you understand and respect them. They make it a priority to engage with you.</Typography>
            </div>
          )
        }
      case 'convey':
        if (type === types.WEAK) {
          return (
            <div key={index} className={classes.section}>
              <Typography className={classes.text} variant="h5">Convey Is Your Untapped Habit</Typography>
              <Typography className={classes.text} variant='body1'>You tend to either under-communicate or over-communicate. Either way, you can inadvertently create skepticism not only of your message, but also of your credibility. Simply put, when you confuse, you lose.</Typography>
            </div>
          )
        } else if (type === types.AVERAGE) {
          return (
            <div key={index} className={classes.section}>
              <Typography className={classes.text} variant="h5">Convey Is Your Occasional Habit</Typography>
              <Typography className={classes.text} variant='body1'>You convey best when you’re confident that you know the subject inside and out. When you feel unprepared, however, you tend to deliver too much information in a disorganized fashion. People may latch on to an unintentional tidbit or end up confused. To expand your skills, focus on developing techniques to simplify your messages.</Typography>
            </div>
          )
        } else {
          return (
            <div key={index} className={classes.section}>
              <Typography className={classes.text} variant="h5">Convey Is Your Dominant Habit</Typography>
              <Typography className={classes.text} variant='body1'>You’re clear and easy to follow. You have a habit of building a solid case using the most significant information in an easy-to-follow manner. As a result, people’s doubts diminish, their certainty grows, and they’re on the path to a positive decision about the topic at hand.</Typography>
            </div>
          )
        }
      case 'convince':
        if (type === types.WEAK) {
          return (
            <div key={index} className={classes.section}>
              <Typography className={classes.text} variant="h5">Convince Is Your Untapped Habit</Typography>
              <Typography className={classes.text} variant='body1'>You tend to come across as either apathetic or controlling, depending upon the situation. As a result, people withhold their commitment and turn to other sources for input.</Typography>
            </div>
          )
        } else if (type === types.AVERAGE) {
          return (
            <div key={index} className={classes.section}>
              <Typography className={classes.text} variant="h5">Convince Is Your Occasional Habit</Typography>
              <Typography className={classes.text} variant='body1'>You champion a cause when you feel passionate about a subject. In those instances, people are inspired by your genuine enthusiasm and are willing to change their minds. Focus on developing meaningful relationships and mentoring others to deepen this habit.</Typography>
            </div>
          )
        } else {
          return (
            <div key={index} className={classes.section}>
              <Typography className={classes.text} variant="h5">Convince Is Your Dominant Habit</Typography>
              <Typography className={classes.text} variant='body1'>Congratulations! You are well respected and have earned elite status. Others are loyal to you and proactively seek your input. You are known as a person who empowers others and helps them succeed. People trust your judgment and join forces with you to help convince others.</Typography>
            </div>
          )
        }
      default:
        break
    }
  }
}

export default withStyles(theme => ({
  section: {
    marginBottom: theme.spacing(2)
  },
  text: {
    lineHeight: 2
  },
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    textAlign: 'left',
  }
}))(Overview)