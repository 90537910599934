import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {apiBase} from 'utilities/api.js'

import {
  CircularProgress,
} from '@material-ui/core'

import ConferenceTable from './table'
import ConferenceReport from './report'

const formatDateField = data => {
  let d = new Date(data)
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
}

function Conferences({userData}) {
  const [conferences, setConferences] = useState([])
  const [isLoading, setLoading] = useState(true)

  const [conferencesToReport, setConferencesToReport] = useState([])

  useEffect(() => {
    if(!isLoading) return
    axios.get(`${apiBase}/admin/conference`, {
      headers: {
        'Authorization': userData.jwt
      },
    })
    .then(({data}) => {
      setLoading(false)
      data.sort((a,b) => b.opens > a.opens ? 1 : -1)
      setConferences(data)
    })
    .catch((response) => console.error(response))
  }, [isLoading, userData.jwt])

  return (
    <div>
      {isLoading ?
        <CircularProgress variant='indeterminate' disableShrink />
        :
        <div>
          {conferencesToReport.length === 0 ?
            <ConferenceTable onGenerateReport={setConferencesToReport} conferences={conferences.map(conference => ({...conference, opens: formatDateField(conference.opens), closes: formatDateField(conference.closes)}))} />
            :
            <ConferenceReport jwt={userData.jwt} conferences={conferencesToReport} onBack={() => setConferencesToReport([])} />
          }
        </div>
      }
    </div>
  )
}

export default Conferences
