import React from 'react'
import AnswerLabel from './answerLabel'
import RadioGroup from '@material-ui/core/RadioGroup'

function AnswerSelection({
  question: {
    answers,
    name,
    type,
    questionType
  },
  handle,
}) {
  return (
    <RadioGroup>
      {answers && answers.map((answer, index) => {
        let tipSelected = false //Tip value of a selected radio button. Changes to tip value if button contains
        let tipVal = false //If question contains a tip, the value is held here
        if (answer.hasOwnProperty('tip')) {
          tipVal = answer.tip
          tipSelected = true
        }
        return (<AnswerLabel key={`${name}.${index}`} handle={handle} answer={answer} questionType={questionType} tipVal={tipVal} tipSelected={tipSelected} type={type} name={name} />)
      })}
    </RadioGroup>
  )
}


export default React.memo(AnswerSelection)
