import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

function AnswerLabel({
  name,
  answer,
  type,
  questionType,
  tipVal,
  tipSelected,
  handle,
}) {

  const label = questionType === 'img' ? <img src={answer.src} alt='' width='50%' /> : answer.text

  return (
    <FormControlLabel
      value={answer.value.toString()}
      control={<Radio />}
      label={label}
      onChange={() => {
        handle({
          tip: {
            value: tipVal,
            selected: tipSelected,
          },
          result: {
            name: name,
            type: type,
            value: answer.value,
          }
        })
      }}
      />
  )

}

export default AnswerLabel;
