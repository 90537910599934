import React, { Component, Fragment } from 'react'
import CalcResultTypes from './calcResultTypes'
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'

class Summary extends Component {
  render() {
    const resultTypes = CalcResultTypes(this.props.scores)
    const classes = this.props.classes
    return (
      <Box className={classes.container}>
        {this.generateSummary(resultTypes)}
      </Box>
    )
  }

  generateSummary(resultTypes) {
    const types = {
      WEAK: 0,
      AVERAGE: 1,
      STRONG: 2,
    }
    const classes = this.props.classes
    let header, description

    header = `You are ${getString(resultTypes.connect)} connector, ${getString(resultTypes.convey)} conveyor, and ${getString(resultTypes.convince)} convincer.`
    if (types.STRONG === resultTypes.connect === resultTypes.convey === resultTypes.convince) {
      header = 'You are a true influencer, strong in every habit – Connect, Convey, and Convince.'
      description = 'Congratulations! You clearly have a good grasp on what it takes to influence others and are able to put it into practice. You understand the difference between manipulation, persuasion, and influence and are excellent at influencing your world. You have mastered these habits and leverage them in many situations, which allows you to make a lasting impact on those you come in contact with.'
    } else if ([resultTypes.connect, resultTypes.convey, resultTypes.convince].includes(types.STRONG)) {
      description = 'Well done. You have mastered some of the fundamental skills to influence others and are on the path to becoming a true influencer. With practice, you can become a strong connector, convincer, and conveyer. You’ll find that when you are mindful of actions, your reactions, and your interactions with others, it becomes easier to put all three habits into practice. Soon you can make a lasting impact on those you come in contact with.'
    } else if (types.AVERAGE === resultTypes.connect === resultTypes.convey === resultTypes.convince) {
      description = 'You’re almost there. You might know some of what it takes to influence others, and the good news is that you can hone these skills with practice. By learning the key principles of connecting, convincing, and conveying, you can learn to transform minds, behaviors, and outcomes. Take it one step at a time - you can advance to become a true influencer and make a lasting impact on those you come in contact with.'
    } else if (types.WEAK === resultTypes.connect === resultTypes.convey === resultTypes.convince) {
      description = 'The good news is that you can learn to enhance these skills!  True influencers didn’t get there without some practice. You might not know how to tap into these habits, but you can learn to with some practice. Your first step is to learn the basic skills behind connecting, convincing, and conveying. Practice applying these skills to everyday life, and they can soon become habits. With time, you will begin to see how others around you begin to respect your opinions more, trust your judgment, and start to seek your input. Take it one step at a time - you can advance to become a true influencer and make a lasting impact on those you come in contact with.'
    } else {
      description = 'Don’t worry, you can learn to become a strong influencer. You might not routinely tap into these habits, but you can hone all of these skills with practice. Your first step is to learn what it takes to truly connect, convince, and convey. Apply these habits to everyday life, and you will begin to see how others around you begin to respect your opinions more, trust your judgment, and start to seek your input. Take it one step at a time - you can advance to become a true influencer and make a lasting impact on those you come in contact with.'
    }

    return (
      <Fragment>
        <Typography variant='h4' color='textSecondary'>{header}</Typography>
        <Typography variant='body1' color='textSecondary' className={classes.text}>{description}</Typography>
      </Fragment>
    )

    function getString(res) {
      if (res === types.WEAK) return 'an untapped'
      if (res === types.AVERAGE) return 'an occasional'
      if (res === types.STRONG) return 'a strong'
    }

  }
}

export default withStyles(theme => ({
  text: {
    lineHeight: 2,
    marginTop: theme.spacing(2)
  },
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    textAlign: 'left',
  },
}))(Summary)