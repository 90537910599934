const TestQuestions =
  [
    {
      type: 'Conveyor',
      question: 'How easy or difficult would it be for you to condense a 10-page report into a highly effective one–page summary?',
      questionType: 'text',
      answers: [
        {
          tip: 1,
          text: 'Very difficult.',
          value: 1,
        },
        {
          text: 'Somewhat difficult.',
          value: 2,
        },
        {
          text: 'Neither easy nor difficult.',
          value: 3,
        },
        {
          text: 'Somewhat easy.',
          value: 4,
        },
        {
          text: 'Very easy.',
          value: 5,
        },
      ]
    },
    {
      type: 'Convincer',
      question: 'How would you rate yourself on setting and enforcing time limits for others to make decisions?',
      questionType: 'text',
      answers: [
        {
          tip: 2,
          text: 'Awful.',
          value: 1.438,
        },
        {
          text: 'Poor.',
          value: 2.875,
        },
        {
          text: 'Fair.',
          value: 4.313,
        },
        {
          text: 'Good.',
          value: 5.75,
        },
        {
          text: 'Very good.',
          value: 7.188,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'Have you been told that you ramble or give too much detail when you talk?',
      questionType: 'text',
      answers: [
        {
          text: 'Never.',
          value: 5,
        },
        {
          text: 'Rarely.',
          value: 4,
        },
        {
          text: 'Occasionally.',
          value: 3,
        },
        {
          text: 'Often.',
          value: 2,
        },
        {
          tip: 3,
          text: 'Always.',
          value: 1,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'How often do you exceed your allotted time while delivering a presentation or other important message?',
      questionType: 'text',
      answers: [
        {
          text: 'Never.',
          value: 5,
        },
        {
          text: 'Rarely.',
          value: 4,
        },
        {
          text: 'Occasionally.',
          value: 3,
        },
        {
          text: 'Often.',
          value: 2,
        },
        {
          tip: 4,
          text: 'Always.',
          value: 1,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'I can usually get what I want, but it can take some convincing.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'I am aware of what is going on around me in the office, I just tend to keep to myself.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'How often do you find yourself interrupting others when they’re talking?',
      questionType: 'text',
      answers: [
        {
          text: 'Never.',
          value: 5,
        },
        {
          text: 'Rarely.',
          value: 4,
        },
        {
          text: 'Occasionally.',
          value: 3,
        },
        {
          text: 'Often.',
          value: 2,
        },
        {
          tip: 5,
          text: 'Constantly.',
          value: 1,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'You are in sales at XYZ Widget. You’re meeting with Pat, a prospective customer. Would this be an effective story to share with Pat? “We’re the #1 manufacturer of widgets. We’ll ship more than a million units to customers like you today.”',
      questionType: 'text',
      answers: [
        {
          tip: 6,
          text: 'Yes.',
          value: 1,
        },
        {
          text: 'No.',
          value: 5,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'When I need a big favor from someone, I ask for a small one first.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'How often have you been told that your face can appear angry or bored, even when you’re not, or that your voice sounds mad when you’re not?',
      questionType: 'text',
      answers: [
        {
          text: 'Never.',
          value: 3.75,
        },
        {
          text: 'Occasionally.',
          value: 2.5,
        },
        {
          tip: 7,
          text: 'Frequently.',
          value: 1.25,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'My opinions are frequently disregarded.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'I am generally described as a leader.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 5,
        },
        {
          text: 'Disagree.',
          value: 4,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 2,
        },
        {
          text: 'Strongly agree.',
          value: 1,
        },
      ]
    },
    {
      type: 'Connector',
      question: 'How would you rate your ability to read other people’s non-verbal cues?',
      questionType: 'text',
      answers: [
        {
          tip: 8,
          text: 'I\'m clueless.',
          value: 1.5,
        },
        {
          text: 'Not very good.',
          value: 3,
        },
        {
          text: 'Fair.',
          value: 4.5,
        },
        {
          text: 'Very good.',
          value: 6,
        },
        {
          text: 'Exceptional.',
          value: 7.5,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'When I assign someone a project or task, I often need to help them understand what is needed from them.',
      questionType: 'text',
      answers: [
        {
          text: 'I don’t assign tasks or projects.',
          value: 0,
        },
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Convincer',
      question: 'Is the following statement true or false about you: “I freely mentor others in my field, and at least one of my mentees has been promoted to a highly visible role.”',
      questionType: 'text',
      answers: [
        {
          text: 'True.',
          value: 7.5,
        },
        {
          tip: 9,
          text: 'False.',
          value: 1.5,
        },
      ]
    },
    {
      type: 'Convincer',
      question: 'How would you rate your ability to state an important point quickly, without rambling or diving into details?',
      questionType: 'text',
      answers: [
        {
          tip: 10,
          text: 'Pathetic.',
          value: 1,
        },
        {
          text: 'Poor.',
          value: 2,
        },
        {
          text: 'Fair.',
          value: 3,
        },
        {
          text: 'Good.',
          value: 4,
        },
        {
          text: 'Exceptional.',
          value: 5,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'When superiors are in the room, I try to fade into the background.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'How often do people interrupt you when you’re speaking?',
      questionType: 'text',
      answers: [
        {
          text: 'Never.',
          value: 7.5,
        },
        {
          text: 'Rarely.',
          value: 6,
        },
        {
          text: 'Occasionally.',
          value: 4.5,
        },
        {
          text: 'Often.',
          value: 3,
        },
        {
          tip: 11,
          text: 'Always.',
          value: 1.5,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'I could do more at work, but there is no need to.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'Which of these slides do you believe would be most influential in a live presentation?',
      questionType: 'img',
      answers: [
        {
          tip: 12,
          src: 'https://www.influence360.com/wp-content/uploads/2016/09/sample_presentation_a.jpg',
          value: 1.84,
        },
        {
          src: 'https://www.influence360.com/wp-content/uploads/2016/09/sample_presentation_b.jpg',
          value: 3.68,
        },
        {
          src: 'https://www.influence360.com/wp-content/uploads/2016/09/sample_presentation_c.jpg',
          value: 5.52,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'When I ask someone to do something for me, I spare the details about the task until they’ve said yes.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'How often do you have to explain something more than once to someone because they didn’t understand what you meant the first time?',
      questionType: 'text',
      answers: [
        {
          text: 'Never.',
          value: 8.5,
        },
        {
          text: 'Rarely.',
          value: 6.8,
        },
        {
          text: 'Occasionally.',
          value: 5.1,
        },
        {
          text: 'Often.',
          value: 3.4,
        },
        {
          tip: 13,
          text: 'Always.',
          value: 1.7,
        },
      ]
    },
    {
      type: 'Connector',
      question: 'How would you rate your ability to learn what a person values?',
      questionType: 'text',
      answers: [
        {
          tip: 14,
          text: 'I\'m clueless.',
          value: 1.7,
        },
        {
          text: 'Not very good.',
          value: 3.4,
        },
        {
          text: 'Fair.',
          value: 5.1,
        },
        {
          text: 'Very good.',
          value: 6.8,
        },
        {
          text: 'Exceptional.',
          value: 8.5,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'I sometimes give up too much to get what I want.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'I feel like I am constantly defending myself and my ideas.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Connector',
      question: 'To what extent do you agree with this statement: “When meeting new people, I appear to be approachable.”',
      questionType: 'text',
      answers: [
        {
          tip: 15,
          text: 'Never true of me.',
          value: 1.84,
        },
        {
          text: 'Sometimes true of me.',
          value: 3.68,
        },
        {
          text: 'Very true of me.',
          value: 5.52,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'Others tell me I am a clear, straightforward thinker.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 5,
        },
        {
          text: 'Disagree.',
          value: 4,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 2,
        },
        {
          text: 'Strongly agree.',
          value: 1,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'I try not to “rock the boat” at work.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Connector',
      question: 'How strong is your network of supporters whom others consider influential in your field?',
      questionType: 'text',
      answers: [
        {
          tip: 16,
          text: 'Pitiful.',
          value: 1,
        },
        {
          text: 'Weak.',
          value: 2,
        },
        {
          text: 'Okay.',
          value: 3,
        },
        {
          text: 'Strong.',
          value: 4,
        },
        {
          text: 'Exceptionally strong.',
          value: 5,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'I tend to stay away from the drama at work. I don’t want to get involved.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'If someone came to me asking for advice, it would make me very nervous.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'Which email structure do you think would lead to a better response?',
      questionType: 'img',
      answers: [
        {
          src: 'https://www.influence360.com/wp-content/uploads/2016/09/sample_email_a.jpg',
          value: 5,
        },
        {
          tip: 17,
          src: 'https://www.influence360.com/wp-content/uploads/2016/09/sample_email_b.jpg',
          value: 1,
        },
      ]
    },
    {
      type: 'Convincer',
      question: 'How would you rate your ability to change a difficult person’s mind when s/he disagrees with your point of view?',
      questionType: 'text',
      answers: [
        {
          tip: 18,
          text: 'Awful.',
          value: 1,
        },
        {
          text: 'Poor.',
          value: 2,
        },
        {
          text: 'Fair.',
          value: 3,
        },
        {
          text: 'Good.',
          value: 4,
        },
        {
          text: 'Very good.',
          value: 5,
        },
      ]
    },
    {
      type: 'Convincer',
      question: 'Is the following statement true or false about you: “People in my industry whom I don’t even know seek my opinion on important issues.”',
      questionType: 'text',
      answers: [
        {
          text: 'True.',
          value: 8.5,
        },
        {
          tip: 19,
          text: 'False.',
          value: 1.7,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'When you feel impatient or frustrated with someone, does it show?',
      questionType: 'text',
      answers: [
        {
          text: 'No one can tell.',
          value: 5,
        },
        {
          text: 'Not often.',
          value: 4,
        },
        {
          text: 'Sometimes.',
          value: 3,
        },
        {
          text: 'Often.',
          value: 2,
        },
        {
          tip: 20,
          text: 'It’s plain as day.',
          value: 1,
        },
      ]
    },
    {
      type: 'Connector',
      question: 'How would you rate yourself at researching new topics?',
      questionType: 'text',
      answers: [
        {
          tip: 21,
          text: 'I hate to research and try to delegate these tasks to others.',
          value: 1.25,
        },
        {
          text: 'I’m not a very good researcher. I find it tedious and often put it off until it must be done.',
          value: 2.5,
        },
        {
          text: 'Research is not my strongest point, but I understand the value of it and will make sure it gets done.',
          value: 3.75,
        },
        {
          text: 'I was born to research and am constantly learning about new ideas, people, and organizations.',
          value: 5,
        },
      ]
    },
    {
      type: 'Convincer',
      question: 'How would you rate your decisiveness?',
      questionType: 'text',
      answers: [
        {
          tip: 22,
          text: 'I change my mind frequently.',
          value: 1,
        },
        {
          text: 'I sometimes change my mind.',
          value: 2,
        },
        {
          text: 'I rarely change my mind.',
          value: 3,
        },
        {
          text: 'I almost never change my mind.',
          value: 4,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'People don’t generally consult with me.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Conveyor',
      question: 'Do you multi-task as you communicate in order to meet deadlines and get things done?',
      questionType: 'text',
      answers: [
        {
          text: 'Never.',
          value: 5,
        },
        {
          text: 'Sometimes.',
          value: 4,
        },
        {
          text: 'Occasionally.',
          value: 3,
        },
        {
          text: 'Often.',
          value: 2,
        },
        {
          tip: 23,
          text: 'Always.',
          value: 1,
        },
      ]
    },
    {
      type: 'Convincer',
      question: 'If you sent 10 emails at 9:00 this morning to people you know, how many responses would you typically have by 12:00 noon?',
      questionType: 'text',
      answers: [
        {
          tip: 24,
          text: '1-3.',
          value: 1.6,
        },
        {
          text: '4-8.',
          value: 3.2,
        },
        {
          text: '9-10.',
          value: 4.8,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'I am very good at getting people to say yes to my plans or ideas.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 5,
        },
        {
          text: 'Disagree.',
          value: 4,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 2,
        },
        {
          text: 'Strongly agree.',
          value: 1,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'People may not always understand the benefit of my ideas at first, but they come around in the end.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Bystander',
      question: 'People would describe me as "the quiet one" in the office.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
    {
      type: 'Persuasive',
      question: 'I spend too much time getting other people to agree with me.',
      questionType: 'text',
      answers: [
        {
          text: 'Strongly disagree.',
          value: 1,
        },
        {
          text: 'Disagree.',
          value: 2,
        },
        {
          text: 'Neither agree nor disagree.',
          value: 3,
        },
        {
          text: 'Agree.',
          value: 4,
        },
        {
          text: 'Strongly agree.',
          value: 5,
        },
      ]
    },
  ]

module.exports = TestQuestions
