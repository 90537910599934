import React from 'react'

import {makeStyles} from '@material-ui/core/styles'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import Table from '../../../components/table.js'

const useStyles = makeStyles(theme => ({
  pipeBar: {
    color: theme.palette.influence.convey,
    fontWeight: 100,
  }
}))


const makeDateEdit = () => props => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      margin="normal"
      value={props.value}
      onChange={d => props.onChange(`${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`)}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      />
  </MuiPickersUtilsProvider>
)

function ConferenceTable({
  title,
  conferences,
  className,
  onAdd = () => new Promise(resolve => resolve(console.error('onAdd NOT IMPLEMENTED'))),
  onEdit = () => new Promise(resolve => resolve(console.error('onEdit NOT IMPLEMENTED'))),
  onDelete = () => new Promise(resolve => resolve(console.error('onDelete NOT IMPLEMENTED'))),
}) {
  const classes = useStyles()
  const columns = [
    {title: 'Group', field: 'tag'},
    {title: 'Code', field: 'passcode'},
    {title: 'Opens', editComponent: makeDateEdit(), field: 'opens'},
    {title: 'Closes', editComponent: makeDateEdit(), field: 'closes'},
    {title: 'Tests', field: 'allowedAttempts'},
    {title: 'Users', render: d => d ? d.second && d.second > 0 ? <span>{d.first}<span className={classes.pipeBar}> | </span>{d.second}</span> : <span>{d.first}</span> : <span>0</span>}
  ]
  return (
    <div className={className ? className : ''}>
      <Table
        title = {title}
        columns = {columns}
        data = {conferences}
        options={{
          search: true,
        }}
        editable={{
          onRowAdd: newData => onAdd(newData),
          onRowUpdate: (newData, oldData) => onEdit(newData, oldData),
          onRowDelete: oldData => onDelete(oldData),
        }}
        />
    </div>
  )
}

export default ConferenceTable
