import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CalcResultTypes from './calcResultTypes'

const types = require('./resultTypes')

class WavyPic extends Component {
  render() {
    const { classes, scores } = this.props
    return (
      <img src={this.generateSrc(scores)} alt='CCC Visual Display' className={classes.img} />
    )
  }

  generateSrc(scores) {
    let results = CalcResultTypes(scores)
    return `https://www.influence360.com/wp-content/uploads/2016/10/${this.getImg(results.connect)}c1-${this.getImg(results.convey)}c2-${this.getImg(results.convince)}c3.png`
  }

  getImg(res) {
    if (res === types.WEAK) return 'w'
    if (res === types.AVERAGE) return 'a'
    if (res === types.STRONG) return 's'
  }
}

export default withStyles(theme => ({
  img: {
    maxWidth: '100%'
  },
}))(WavyPic)