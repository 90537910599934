import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles';

class Tip extends Component {
  render() {
    const { classes, value } = this.props
    return (
      <Typography variant='body1' color='textSecondary' className={classes.tip}>
        <strong>Tip: </strong>{value}
      </Typography >

    )
  }
}

export default withStyles(theme => ({
  tip: {
    marginTop: theme.spacing(2)
  },
}))(Tip)